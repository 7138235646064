//@use light theme colors 
@use "../../Light.scss" as colors;

.pages-content-container {
    .pages-content-container {}
}

// .sitepages-nav {
//     display: inline-flex;
//     flex-wrap: wrap;
//     gap: 1rem;
// }

.sitepages-home-container {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    align-items: center;
    justify-content: center;
    .sitepages-search-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: colors.$content-background;
        border-radius: 16px;
        padding: 16px 8px;
        h3 {
            font-weight: 600;
        }
        .sitepages-search-input-container {
            input {
                height: 100%;
                width: 95%;
                padding: 8px;
                border-radius: 16px;
                border: 1px solid #65676b;
            }
        }
    }
}

.sitepages-search-input-container>div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    height: 40px;
    width: 40px;
    background-color: colors.$buttonsNbars;
    border-radius: 7px;
    color: colors.$buttonsNbarsText;
}

.sitepages-search-input-container>select {
    height: 100%;
    margin-left: 16px;
}

.sitepages-home-content-grid {
    display: grid;
    width: 100%;
    max-width: 100%;
    height: 100%;
    grid-template-columns: 0.4fr 1fr 0.4fr;
    margin-top: 16px;
    column-gap: 1rem;
}

.sitepages-home-content-grid>div {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    grid-template-rows: max-content;
}

.sitepages-content-card {
    background-color: colors.$content-background;
    border-radius: 16px;
    padding: 16px;
}

.sitepages-content-card-title {
    h5 {
        font-weight: 600;
    }
}

.sitepages-content-card.create-page {
    background-color: transparent;
    padding: 8px 16px;
}

.featured-pages-carousel {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
}

.featured-pages-carousel-border {
    height: 140px;
    width: 140px;
}

.featured-pages-carousel-img {
    height: 140px;
    width: 140px;
    object-fit: cover;
}

.featured-pages-carousel-buttons {
    width: 100%;
    justify-content: center;
}

.featured-pages-carousel-buttons>svg {
    width: min-content;
    cursor: pointer;
}

.featured-pages-carousel-actions-view-page>a {
    color: inherit;
}

.carousel-button-active {
    color: colors.$buttonsNbars;
}

.sitepages-display-sm {
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    margin-bottom: 10px;
    &:hover {
        background-color: colors.$highlight;
    }
    img{
        width: 64px;
        height: 64px;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid #e4e4e4;
    }
}

.sitepages-display-description {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    padding-left: 12px;
    strong {
        color: colors.$default-text
    }
    small {
        color: colors.$subtext;
    }
}

.sitepages-display-description>p {
    margin: 0;
}

.sitepages-display-description>a {
    color: inherit;
}

.sitepages-content-card.create-page button {
    background-color: colors.$buttonsNbars;
    border: none;
    border-radius: 15px;
    outline: none;
    color: colors.$buttonsNbarsText;
    text-align: center;
    padding: 8px;
    font-size: 1.4rem;
    font-weight: 600;
    width: 100%;
}

.sitepage-view-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    height: 100%;
}

.sitepage-view-wrapper {
    display: grid;
    grid-template-rows: 0.3fr 1.0fr;
    width: 100%;
    row-gap: 1rem;
    height: 100%;
}

.sitepage-header {
    background-color: colors.$content-background;
    border-radius: 16px;
}

.sitepage-header-wrapper {
    height: 300px;
    margin: 16px;
    background-color: colors.$content-background;
    position: relative;
}

.sitepage-header-coverphoto {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;
    cursor: pointer;
}

.sitepage-image-container {
    width: 134px;
    height: 134px;
    position: absolute;
    background-color: colors.$content-background;
    padding: 4px;
    bottom: -20%;
    left: 16px;
    border-radius: 4px;
    border: 2px solid colors.$border-all;
}

.sitepage-image-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.sitepage-header-buttons {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-end;
    padding-right: 16px;
    margin-bottom: 24px;
}

.sitepage-header-text {
    padding-left: 32px;
}

.sitepage-header-text>h4 {
    color: colors.$default-text;
    font-weight: 700;
}

.sitepage-header-text>p {
    color: colors.$mute-text;
    font-weight: 600;
}

.sitepage-view-content {
    background-color: colors.$content-background;
    border-radius: 16px;
    padding: 8px;
}

.sitepage-view-nav {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 10px;
    border-bottom: 1px solid colors.$highlight;
    width: 100%;
}

.sitepage-active-nav {
    color: colors.$buttonsNbars;
    border-bottom: 1px solid colors.$buttonsNbars;
}

.sitepage-nav-item {
    padding: 10px;
    margin: 0;
    cursor: pointer;
    user-select: none;
}

.sitepage-nav-item:hover {
    color: colors.$buttonsNbars;
}

.sitepages-about-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    padding: 16px;
}

.sitepage-view-content > div > h3,
.sitepage-reviews-wrapper {
    padding: 16px;
}

.sitepage-photos {
    padding-top: 16px;
}

.sitepage-photos-list {
    padding-top: 32px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 5rem;
}

.sitepage-photos-create-album {
    cursor: pointer;
}

.sitepage-photos-create-album:hover {
    color: colors.$buttonsNbars
}

// Media Queries
@media (max-width: 390px) {.sitepages-display-description > a {
        font-size: 14px;
    }
}

@media (max-width: 540px) {
    .sitepage-header-wrapper {
        height: 250px;
    }
}

@media (max-width: 767px) {
    .sitepages-home-container {
        width: 100% !important;
    }

    div .liked-followed-sitepages-columns {
        flex-direction: column !important;
        .sitepages-content-card {
            width: 100% !important;
        }
    }

    div .sponsored-recently-viewed-sitepages-columns {
        flex-direction: column !important;
        .sitepages-content-card {
            width: 100% !important;
        }
    }
    
    .sitepages-display-sm img {
        width: 105px;
        height: 105px;
    }
}

@media (max-width: 920px) {
    .sitepages-home-container {
        width: 97%;
        margin-left: auto;
        margin-right: auto;
        .sitepages-search-input-container {
            width: 100%;
            input {
                border: 1px solid #65676b;
            }
            div {
                border-radius: 16px;
            }
        }
    }

    .sitepages-home-content-grid {
        display: block;
        div {
            .sitepages-content-card {
                .sitepages-display-sm {
                    a {
                        img {
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 50% !important;
                        }
                    }
                }
            }
        }
    }

    div .liked-followed-sitepages-columns {
        display: flex;
        flex-direction: row;
        .sitepages-content-card {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 8px;
            width: 48%;
        }
    }

    div.sponsored-recently-viewed-sitepages-columns {
        display: none !important;
    }

    div.sponsored-recently-viewed-sitepages-columns-small-screens {
        display: flex !important;
        flex-direction: column;
        margin-bottom: 16px;
        .sitepages-content-card.create-page {
            display: none;
        }
        .sitepages-content-card {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }
    }

    div .sponsored-recently-viewed-sitepages-columns {
        display: flex;
        flex-direction: row;
        .sitepages-content-card {
            margin-left: auto;
            margin-right: auto;
        }
    }
}