//use light color scheme 
@use "../../Light.scss" as colors;

.post-page-container {
    height: 100%;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    .post-page-content {
        background-color: colors.$content-background;
        border-radius: 1rem;
        width: 75%;
        margin: auto;
        .post-item {
            height: 100%;
            width: 100%;
            background-color: colors.$content-background;
            border: 1px solid #e4e0e0;
            border-radius: 1rem;
            padding: 0;
            &.has-attachment {
                grid-template-rows: 0.1fr min-content 0.4fr min-content;
                .post-item-actions {
                    border-top: 1px solid colors.$highlight;
                    margin-top: 1rem;
                }
            }
            .post-item-header {
                display: grid;
                grid-template-columns: 0.07fr 0.2fr;
                padding: 0.5rem;
                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    object-fit: cover;
                }
                .post-item-header-text {
                    a {
                        text-decoration: none;
                        color: colors.$default-text;
                        cursor: pointer;
                        &:hover {
                            color: colors.$alt-link-hover;
                        }
                    }
                }
                .post-item-header-title {
                    font-size: 1rem;
                    font-weight: 600;
                    margin-bottom: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .post-item-header-date {
                    color: colors.$subtext;
                    font-size: 0.8rem;
                }
            }
            .post-item-body {
                padding: 0.3rem 0.5rem;
                overflow-wrap: break-word;
                white-space: pre-line;
            }
            .post-item-attachment-carousel {
                background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);
                border-radius: 1rem;
            }
            .post-item-actions {
                display: flex;
                justify-content: space-evenly;
                border-bottom: 1px solid colors.$highlight;
                padding: 0.5rem;
                div {
                    font-size: 1.1rem;
                    cursor: pointer;
                }
            }
        }
    }
}

.post-page-item-container {
    width: 100% !important;
    max-height: 100% !important;
    height: 480px;
}

.post-page-item-attachment-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
    max-height: 100% !important;
    height: 480px;
    border-radius: 1rem;
    object-fit: contain;
    cursor: pointer;
    background: radial-gradient(circle at 18.7% 37.8%, #fafafa 0%, #e1eaee 90%);
}

.post-page-item-container {
    .modal-ex {
        max-width: fit-content;
    }
}

.share-container {
    textarea {
        resize: none;
        width: 100%;
        min-height: 60px;
        max-height: 250px;
        line-height: normal;
        font-size: 1rem;
        box-shadow: none;
        border: none;
        overflow: auto;
        clear: both;
        border: 1px solid colors.$buttonsNbars;
    }
    .share-item {
        border: 1px solid colors.$highlight;
        padding: 1rem;
        margin-top: 1rem;
        .share-item-header {
            display: grid;
            grid-template-columns: 0.15fr 0.85fr;
            margin-bottom: 0.5rem;
            .share-item-header-title {
                display: flex;
                flex-direction: column;
                font-size: 1rem;
                font-weight: 600;
                .share-item-header-date {
                    font-weight: normal;
                    font-size: 0.8rem;
                }
            }
        }
    }
}

.post-item-attachment-link {
    display: flex;
    width: 100%;
    height: 7rem;
    padding: 0.5rem;
    background-color: colors.$highlight;
    border-radius: 1rem;
    border: 1px solid colors.$subtext;
    width: 100%;
    a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        .post-item-attachment-link-image {
            img {
                width: 5rem;
                height: 5rem;
                object-fit: cover;
            }
        }
        .post-item-attachment-link-content {
            margin-left: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .post-item-attachment-link-title {
                color: colors.$default-text;
                font-weight: 600;
                font-size: 1.25rem;
            }
        }
    }
}

.comment-item-body {
    padding: 0 0.5rem;
    overflow-wrap: anywhere;
}

// Post attachments: Page objects (events, groups, pages) 
.post-page-attachment {
    width: 100%;
    height: 7rem;
    border: 1px solid colors.$subtext;
    border-radius: 1rem;
    padding: 0.25rem;
    a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        .post-page-attachment-image {
            margin: 0 0.5rem;
            img {
                display: block;
                width: 85px;
                height: 85px;
            }
        }
        .post-page-attachment-content {
            .post-page-attachment-title {
                color: colors.$default-text;
                font-weight: 600;
                font-size: 1.25rem;
            }
            div {
                color: colors.$subtext;
                font-weight: 600;
            }
        }
    }
}

// Media queries
@media only screen and (max-width: 1216px) {
    .post-page-container {
        width: 1000px;
    }

    .post-page-container .post-page-content .post-item .comment-container .comment-item .comment-item-body {
        margin-left: 0.5rem;
    }

    // .post-page-container .post-page-content .post-item .comment-container .comment-item .comment-item-body p {
    //     width: 500px;
    // }
}

@media only screen and (max-width: 1199px) {
    .post-item-header-text {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 1016px) {
    .post-page-container {
        width: 720px !important;
    }
}

@media only screen and (max-width: 430px) {
    .post-page-container {
        width: 430px !important;
    }

    .post-page-container .post-page-content {
        width: 100%;
        margin: 0 auto 0 auto;
    }
}