// Use light color scheme 
@use "../Light.scss" as colors;

//Styles for component nav bar
.component-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 6rem;
    width: 100%;
    background-color: colors.$buttonsNbars;
    margin-top: 16px;
    .component-nav-item {
        background-color: colors.$buttonsNbars;
        padding: 16px;
        border-right: 1px solid black;
        font-size: 1.2rem;
        color: colors.$buttonsNbarsText;
        overflow: hidden;
    }
    .component-nav-item.active {
        background-color: colors.$buttonsNBarsDarkHover;
    }
    .component-nav-item:hover {
        cursor: pointer;
        background-color: colors.$buttonsNBarsDarkHover;
    }
    :last-child {
        border-right: none;
    }
}