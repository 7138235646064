// @use light color scheme
@use "../../Light.scss" as colors; 

.invite-page-container {
    background-color: colors.$content-background;
    border-radius: colors.$content-card-border-round;
    padding: 1rem;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    .invite-page-header {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    .invite-page-content {
        form {
            div.custom-message-invite-friends {
                textarea {
                    border-radius: 16px;
                    resize: none;
                }
            }
            button.send-invite-friends {
                border-radius: 16px !important;
            }
        }
    }

    .invite-page-form-email-input-add {
    }

    .invite-page-email-input-group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 0.5rem;
    }

    .invite-page-form-email-input {
        display: flex;
        max-width: 30rem;
        input {
            width: 80%;
            border-radius: 15px;
        }
        button {
            margin-left: 10px;
            border-radius: 7px;
        }
    }
}

.invite-page-form-email-input-add {
    font-size: 1rem;
    font-weight: 600;
    padding: 0.375rem 0.75rem;
    border-radius: 7px;
}

.custom-message-invite-friends {
    margin-top: 1rem;
}

// Media Queries
@media only screen and (min-width: 1200px) {
    .invite-page-container {
        width: 1200px;
    }
}