@use "../../Light.scss" as colors; 


.stories-widget-container {
    display: flex; 
    flex-direction: row; 
    justify-content: flex-start;
    column-gap: 2em;
    align-items: center;
    background-color: colors.$content-background; 
    height: 5em;
    padding: 1em;
    margin-bottom: 1rem;
    border-radius: 16px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2)
}

.stories-widget-item {
    width: 3em; 
    
    img { 
        border-radius: 50%;
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
        background-color: colors.$main-background;
        border: 3px solid #0000;

        &.active {
            background: linear-gradient(colors.$main-background, colors.$main-background) padding-box, linear-gradient(
            var(--angle),
            colors.$main-background,
            colors.$buttonsNbars
            ) border-box;
            animation: 6s rotate linear infinite;
            cursor: pointer;
        }
    }
}

@keyframes rotate {
    to {
      --angle: 360deg;
    }
  }
  
  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }


/* Container with 0.56:1 aspect ratio */
.story-widget-player {
    position: relative;
    width: 100%;
    height: 90vh;
    padding-top: 56%; /* 0.56:1 aspect ratio */
    overflow: hidden;
    background-color: #000;
}

.story-options {
    position: absolute; 
    top: 0.5em; 
    right: 0.5em; 
    font-size: 2em;
    color: white; 
    cursor: pointer; 
}
.story-options-menu {
    display: none; 
    flex-direction: column; 
    border-radius: 7px; 
    position: absolute; 
    background-color: white;
    right: 0;

    &.active {
        display: flex; 
    }

    .menu-item {
        font-size: 1rem; 
        font-weight: bold; 
        color: black;
        // padding-right: 1em; 
        // padding-left: 1em;
        padding: 0.5em;
        padding-right: 2em; 
        padding-left: 2em;
        border-bottom: solid 1px #e4e4e4;
        &:hover {
            color: colors.$buttonsNbars;
            background-color: colors.$main-background;
        }
        &:last-child {
            border-bottom: none; 
            border-bottom-right-radius: 7px; 
            border-bottom-left-radius: 7px; 
        }
        &:first-child {
            border-top-right-radius: 7px; 
            border-top-left-radius: 7px;
        }
    }
}

.story-progress-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    gap: 4px;
    z-index: 10;
}

.story-progress-bar {
    flex: 1;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    overflow: hidden;
}

.story-progress-bar.active {
    background-color: rgba(255, 255, 255, 0.8);
    animation: story-progress-animation linear forwards;
}

@keyframes story-progress-animation {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}


/* Video element styling */
.story-widget-player video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block; 
    object-fit: cover;
    cursor: pointer; 
}

.story-widget-player img {
    position: absolute;
    top: 0; 
    left: 0; 
    height: 100%; 
    width: 100%; 
    display: block; 
    object-fit: cover; 
    padding: 2em;
    cursor: pointer; 
}

  @keyframes story-progress-animation {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

.add-story-button {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    border-radius: 50%; 
    background-color: colors.$buttonsNbars;
    width: 3em;
    height: 3em;
    color: colors.$buttonsNbarsText;   
    cursor: pointer;
    svg {
        font-size: 1.5em;
    }
}

.add-story-button:hover {
    svg {
        font-size: 1.7em;
    }
}