//@use light color scheme
@use "../../Light.scss" as colors;

.posts-loading-icon-wrapper {
    display: flex;
    justify-content: center;
}

.my-pages-list-loading-icon-wrapper {
    display: flex;
    justify-content: center;
}

.message-box-loading-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    svg {
        margin-left: auto;
        margin-right: auto;
    }
}