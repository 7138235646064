// @use Light theme colors 
@use "../../../Light.scss" as colors; 

// Settings file for Profile Settings Layout component 
.profile-settings-container {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    .profile-settings-nav {
        background-color: colors.$content-background;
        border-radius: colors.$content-card-border-round;
        padding: 1rem;
        margin-top: 1rem;
        width: 100%;
        overflow: auto;
        white-space: nowrap;
        .profile-settings-nav-header {
            display: inline;
            margin-right: 1rem;
            font-size: 1.5rem;
            font-weight: 600;
        }
        .profile-settings-nav-item {
            height: 100%;
            display: inline;
            margin-right: 1rem;
            color: black;
            font-size: 1.1rem;
            font-weight: 600;
            &.active {
                color: colors.$alt-link-hover;
            }
        }
        a:hover {
            display: inline-block;
        }
    }
    .profile-settings-content {
        background-color: colors.$content-background;
        border-radius: colors.$content-card-border-round;
        padding: 1rem;
        margin-top: 1rem;
        width: 100%;
        margin-bottom: 1rem;
        .profile-settings-form-container {
            form {
                div {
                    .form-label {
                        font-size: 1.25rem;
                        font-weight: 600;
                    }
                    input, select {
                        border-radius: 15px;
                    }
                }
                button {
                    border-radius: 15px;
                }
            }
            label {
                width: 100%;
            }
            select {
                width: 20%;
            }
            h5 {
                font-size: 1.25rem;
                font-weight: 600;
            }
            .profile-settings-form-section-header {
                margin-top: 0.5rem;
                overflow: hidden;
                text-align: center;
                font-size: 1.2rem;
                &::before,
                &::after {
                    background-color: #000;
                    content: "";
                    display: inline-block;
                    height: 1px;
                    position: relative;
                    vertical-align: middle;
                    width: 50%;
                }
                &::before {
                    right: 0.05em;
                    margin-left: -50%;
                }
                &::after {
                    left: 0.05em;
                    margin-right: -50%;
                }
            }
        }
    }
}

// Media Queries
@media only screen and (max-width: 430px) {
    .profile-settings-container {
        width: 95%;
    }

    .profile-settings-container .profile-settings-nav,
    .profile-settings-content {
        margin: 0 auto 0 auto;
    }
}

@media only screen and (max-width: 768px) {
    .profile-settings-nav {
        display: flex;
        flex-direction: column;
        .profile-settings-nav-item  {
            width: 10%;
        }
    }
}

@media (max-width: 1024px) {
    .profile-settings-container .profile-settings-content .profile-settings-form-container select {
        width: 270px;
    }
}

@media only screen and (min-width: 1200px) {
    .profile-settings-container {
        width: 1200px;
    }
}