//@use light color scheme
@use "../../Light.scss" as colors;

.messages-container {
    height: 83%;
    max-height: 83%;
    width: 1200px;
    background-color: colors.$content-background;
    margin: 1rem auto 0 auto;
    padding: 0.5rem 0;
    border-radius: 1rem;
    .messages-actions {
        display: grid;
        grid-template-columns: 0.3fr 0.9fr;
        column-gap: 1rem;
        height: 2rem;
        margin-right: 0.25rem;
        margin-bottom: 0.50rem;
        padding-bottom: 3rem;
        border-bottom: 1px solid colors.$alt-border-bottom;
        .messages-actions-icons {
            display: flex;
            justify-content: space-between;
            font-size: 1.5rem;
            .messages-actions-header {
                font-weight: 700;
                margin-left: 1rem;
            }
            .messages-actions-icons-fa {
                svg {
                    cursor: pointer;
                }
            }
        }
        .messages-actions-search {
            input { 
                width: 75%;
            }
        }
    }
}

.messages-container-content {
    display: grid;
    grid-template-columns: 0.3fr .9fr;
    .messages-list {
        overflow-y: auto;
        overflow-x: hidden;
        height: 820px;
        max-height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-right: 1px solid colors.$alt-border-bottom;
        .messages-list-item {
            display: grid;
            grid-template-columns: 0.3fr 0.7fr;
            height: 12.5%;
            color: colors.$mute-text;
            cursor: pointer;
            border-bottom: solid 1px colors.$border-all;
           
            .messages-list-item-img {
                display: flex;
                justify-content: center;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 0.5rem;
                position: relative;
                height: 4rem;
                width: 4rem;
                img {
                    height: 100%; 
                    width: 100%; 
                    border-radius: 50%;
                }
                .messages-list-item-online-status {
                    //border: 1px solid rgba(0,0,0,0.3);
                    border-radius: 50%; 
                    position: absolute; 
                    height: 20px;
                    width: 20px; 
                    z-index: 1; 
                    bottom: 0; 
                    right: 0;
                    &.offline {
                        background-color: rgb(210, 215, 211);
                    }
                    &.online {
                        background-color: limegreen;
                    }
                }
            }
            .messages-list-item-preview {
                display: flex;
                justify-content: center;
                color: inherit;
                flex-direction: column;
                .display-sender-name {
                    font-weight: 700;
                }
            }
            &:hover {
                color: colors.$default-text;
                background-color: colors.$highlight;
                &:last-child {
                    border-bottom-left-radius: 1rem;
                }
            }
        }
    }
}

.messages-chat {
    width: 100%;
    height: 770px;
    display: grid;
    // grid-template-rows: 0.05fr 0.45fr 0.05fr;
    .message-box-footer {
        height: 150%;
    }
}

.compose-message-search {
    position: relative;
    width: 75%;
    input.form-control {
        border-radius: 16px;
    }
    .compose-message-search-preview {
        position: absolute;
        height: 20rem;
        top: 100%;
        z-index: 1;
        background-color: colors.$content-background;
        overflow-y: auto;
        border: 2px solid colors.$highlight;
        border-radius: 16px;
        width: inherit;
        overflow-x: hidden;
        .compose-message-search-result {
            display: grid;
            grid-template-columns: 0.3fr 0.7fr;
            height: 4rem;
            color: colors.$mute-text;
            cursor: pointer;
            margin: 0.5rem 0;
            .compose-message-search-result-img {
                display: flex;
                justify-content: center;
                margin-top: auto;
                margin-bottom: auto;
                img {
                    height: 4rem;
                    width: 4rem;
                    border-radius: 50%;
                }
            }
            .compose-message-search-result-name {
                display: flex;
                justify-content: center;
                color: inherit;
                flex-direction: column;
                font-weight: 700;
            }
            &:hover {
                color: colors.$default-text;
                background-color: colors.$highlight;
            }
        }
    }
}

// Media queries

@media (max-width: 430px) {
    .messages-container {
        height: 87%;
        max-height: 87%;
    }

    .messages-actions-icons-fa {
        margin-left: 1rem;
    }

    .compose-message-search,
    .messages-container .messages-actions .messages-actions-search input {
        width: 100%;
    }

    .messages-container-content {
        grid-template-columns: 0.4fr 0.9fr !important;
    }

    .messages-container-content .messages-list {
        height: 600px !important;
    }

    .messages-chat {
        height: 657px !important;
    }

    .message-box-content {
        height: 540px !important;
    }
}

@media (min-width: 431px) and (max-width: 768px), (min-height: 932px) and (max-height: 1180px) {
    .messages-container {
        height: 95%;
        max-height: 100%;
    }
}

@media (max-width: 600px) {
    .messages-container-content {
        grid-template-columns: 0.25fr 0.75fr !important;
    }

    .messages-container-content .messages-list .messages-list-item {
        grid-template-columns: 1fr;
    }

    .display-sender-name {
        text-align: center;
    }

    .display-message-preview {
        display: none;
    }

    .messages-container-content .messages-list .messages-list-item .messages-list-item-img {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media only screen and (max-width: 834px) {
    .display-sender-name,
    .display-message-preview {
        font-size: 13px;
    }

    .messages-container .messages-list .messages-list-item .messages-list-item-img img {
        height: 3rem !important;
        width: 3rem !important;
    }
}

@media (max-width: 920px) {
    .messages-container {
        margin: 0 auto;
    }

    .messages-container-content {
        grid-template-columns: 0.3fr 0.7fr;
    }

    .messages-container-content .messages-list {
        height: 750px;
    }

    .messages-chat {
        height: 725px;
    }

    .message-box-content {
        height: 600px;
    }
}

@media only screen and (max-width: 1024px) {
    .messages-container .messages-list .messages-list-item {
        height: 20%;
    }

    .messages-container .messages-list .messages-list-item .messages-list-item-img img {
        height: 3.5rem;
        width: 3.5rem;
    }

    .messages-container-content .messages-list .messages-list-item .messages-list-item-img {
        margin-left: 0.25rem;
    }
}

@media (max-width: 1368px) {
    .messages-container {
        width: 100%;
    }
}