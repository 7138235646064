// use Light theme colors 
@use "../../Light.scss" as colors; 

// Styles file for Pages Home Layout component
.pages-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    .pages-nav {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: colors.$content-background;
        border-radius: colors.$content-card-border-round;
        padding: 1rem;
        width: 100%;
        border-radius: 16px;
        .pages-nav-header {
            display: inline;
            font-size: 2rem;
            font-weight: 700;
        }
        .pages-nav-item {
            height: 100%;
            display: inline;
            margin-right: 2rem;
            color: black;
            font-size: 1.5rem;
            font-weight: 600;
            &.active {
                color: colors.$alt-link-hover;
            }
        }
    }
}

// Media Queries
@media (max-width: 375px) {
    .pages-container .pages-nav {
        display: flex;
        flex-direction: column;
    }

    .pages-container .pages-nav .pages-nav-item.active {
        border-bottom: none;
    }
}

@media (max-width: 720px) {
    .pages-container {
        width: 95%;
        .pages-nav {
            display: flex;
            flex-direction: column;
            align-items: start;
            .pages-nav-header {
                font-size: 1.5rem;
            }
            .pages-nav-item {
                font-size: 1rem;
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .pages-container {
        width: 1200px;
    }
}