// @TODO: Complete modular SCSS imports for all components 

//Start Modular SCSS Imports

//Import Light color scheme
@use "./Light.scss" as colors;

// ---------------------------------------------------
//Import Header files 
@use "./components/Widgets/HeaderListWidget.scss" as HeaderListWidget; 

//Import Profile Settings files
@use "./components/Profile/Settings/Layout.scss";
@use "./components/Profile/Settings/Subscription.scss"; 
// ---------------------------------------------------
// Import Pages files 
@use "./components/Pages/Layout.scss" as PagesLayout; //PagesLayout
@use "./components/Pages/CreatePage.scss" as CreatePage; //CreatePage
@use "./components/Pages/BrowsePages.scss" as BrowsePages; //BrowsePages
@use "./components/Pages/MyPages.scss" as MyPages; //MyPages
@use "./components/Pages/FeaturedPages.scss" as FeaturedPages; //FeaturedPages
@use "./components/Pages/Pages.scss" as Pages; 

// Import Pages Dashboard 
@use "./components/Pages/Dashboard/Layout.scss" as PagesDashboardLayout; //PagesDashboardLayout
@use "./components/Pages/Dashboard/EditInfo.scss" as EditInfo; //EditInfo
//Import Pages Dashboard Admin Files
@use "./components/Pages/Dashboard/Admin/Manage.scss" as PagesDashboardAdminManage; //PagesDashboardAdminManage


// ---------------------------------------------------
// Import Groups files 
@use "./components/Groups/Edit.scss"; //Edit Group 
@use "./components/Groups/GroupViewLayout.scss"; //Group View Layout 

// ---------------------------------------------------
// Import Notification Files 
@use "./components/Notifications/Notifications.scss" as Notifications; //Notifications

// ---------------------------------------------------
// Import Home files 
@use "./components/Home/Home.scss" as Home; //Home


// Import Invite files 
@use "./components/Invite/Invite.scss" as Invite; //Invite


//Import Advertise files 
@use "./components/Advertise/Layout.scss" as AdvertiseLayout; //Advertise 
@use "./components/Advertise/Advertise.scss" as Advertise; 

// Import Search files 
@use "./components/Search/Search.scss" as Search; //Search 

//Import Signup files 
@use "./components/Signup/Signup.scss" as Signup; //Signup

//Import Messaging files 
@use "./components/Widgets/Messaging.scss" as Messaging; //Messaging


//Import Loading Icons files 
@use "./components/Widgets/LoadingIcons.scss" as LoadingIcons; //LoadingIcons

//Import Form Error Message files 
@use "./components/Widgets/FormErrorMessage.scss" as FormErrorMessage; //FormErrorMessage

//Import Videos Pages files 
@use "./components/Videos/Layout.scss" as VideosLayout; //VideosLayout
@use "./components/Videos/MyVideos.scss" as MyVideos; //MyVideos
@use "./components/Videos/Browse.scss" as Browse; //BrowseVideos
@use "./components/Videos/CreateVideo.scss" as CreateVideo; //CreateVideo
@use "./components/Videos/BrowsePlaylists.scss" as BrowsePlaylists; //BrowsePlaylists
@use "./components/Videos/VideoCategories.scss" as VideoCategories; //VideoCategories

//Import Events Pages Files 
@use "./components/Events/Layout.scss" as EventsLayout; //EventsLayout
@use "./components/Events/MyEvents.scss" as MyEvents; //MyEvents
@use "./components/Events/UpcomingEvents.scss" as UpcomingEvents; //UpcomingEvents
@use "./components/Events/CreateEventPage.scss" as CreateEventPage; //CreateEvent
@use "./components/Events/PastEvents.scss" as PastEvents; //PastEvents
@use "./components/Events/EventView.scss" as EventView; //EventView
@use "./components/Events/EventEdit.scss" as EventEdit; //EventEdit

//Import Posts Pages file 
@use "./components/Posts/Posts.scss" as Posts; //Posts

//Import VideoDisplay Style file 
@use "./components/Widgets/VideoDisplay.scss" as VideoDisplay; //VideoDisplay

//Import Messages Style file 
@use "./components/Messages/messages.scss" as Messages; //Messages

//Default General Styles 
@use "./base/ComponentNavbar.scss"; //ComponentNavbar

//Import ads widget 
@use "./components/Widgets/AdsWidget.scss" as AdsWidget; //AdsWidget

//Import Upload Cover Photo widget
@use "./components/Widgets/UploadCoverPhoto.scss" as UploadCoverPhoto; //UploadCoverPhoto

//Import Stories widget 
@use "./components/Widgets/StoriesWidget.scss" as StoriesWidget; //StoriesWidget

.virtual-list-no-scrollbars {
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
}

.virtual-list-no-scrollbars::-webkit-scrollbar {
    display: none;
}

.default-link {
  color: black;
  text-decoration: none;
  &:hover {
    color: colors.$alt-link-hover;
  }
}

.loading-icon-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  color: inherit;
  justify-content: center;
  align-items: baseline;
  margin-top: 16px;
  &.loading-align-top {
    align-items: flex-start;
  }
}

.post-item-posted-date {
  a {
    color: colors.$subtext;
    text-decoration: none;
    &:hover {
      color: colors.$alt-link-hover;
    }
  }
}

.list-placeholder {
  width: 100%;
  padding: 16px;
  text-align: center;
  color: black;
  background-color: colors.$content-background;
  height: 4rem;
  border-radius: 16px;
}

//OVERRIDES 
.react-datepicker__time-list {
  overscroll-behavior: contain;
}

.emoji-scroll-wrapper {
  overscroll-behavior: contain;
}