//@use light theme colors 
@use "../../Light.scss" as colors;

.message-box {
    display: none;
    &.active-show {
        display: grid;
    }
    position: fixed;
    bottom: 0;
    right: 1rem;
    width: 20rem;
    height: 35rem;
    border: 1px solid black;
    border-radius: 1rem;
    z-index: 10;
    background-color: colors.$content-background;
    grid-template-rows: 3.5rem 1fr 3.5rem;
    .message-box-content {
        height: 100%;
    }
}

.message-box-header {
    display: grid;
    grid-template-columns: 2.5rem 1fr 2rem;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 0.25rem;
    .message-box-header-image {
        max-width: 100%;
        max-height: 100%;
        justify-self: center;
        align-self: center;
        img {
            display: block;
            width: 100%;
            height: auto;
            border-radius: 50%;
        }
    }
    .message-box-header-name {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: left;
        justify-self: left;
        font-size: 1rem;
        margin-left: 0.75rem;
        font-weight: 600;
        a {
            color: colors.$default-text;
            &:hover {
                color: colors.$hover;
            }
        }
    }
    .message-box-header-actions {
        display: flex;
        gap: 0.5rem;
        align-content: center;
        justify-content: center;
        margin-right: 0.5rem;
        align-self: right;
        justify-self: right;
        svg {
            cursor: pointer;
            font-size: 1.2rem;
        }
    }
}

.message-box-content {
    height: 700px;
    padding: 0.25rem;
    overflow-y: auto;
    .message-box-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        &.sent-message {
            justify-content: flex-end;
            .message-box-item-content {
                background-color: colors.$buttonsNbars;
                color: white;
            }
        }
        &.received-message {
            justify-content: flex-start;
            .message-box-item-content {
                background-color: colors.$highlight;
                color: black;
            }
        }
        .message-box-item-content {
            width: 50%;
            text-align: left;
            padding: 0.5rem 0.75rem;
            border-radius: 1rem;
        }
    }
}

.message-box-footer {
    display: grid;
    grid-template-columns: 3rem 1fr 3rem;
    align-items: center;
    justify-items: center;
    border-top: 1px solid black;
    .message-action {
        svg {
            cursor: pointer;
            &:hover {
                color: colors.$buttonsNbars;
            }
        }
    }
}

.message-input {
    width: 100%;
    textarea {
        resize: none;
        width: 100%;
        min-height: 50px;
        max-height: 50px;
        line-height: normal;
        box-shadow: none;
        overflow: auto;
        clear: both;
        outline: 0;
        border: solid 1px colors.$border-all;
        border-radius: 1rem;
        padding: 0.25rem 0.5rem;
    }
}

.message-action-menu-wrapper {
    position: relative;
    .message-action-menu {
        position: absolute;
        display: none;
        right: 0;
        background-color: colors.$content-background;
        border-radius: 7px;
        border: 1px solid black;
        min-width: 7rem;
        &.show {
            display: flex;
        }
        flex-direction: column;
        gap: 0.5rem;
        text-align: center;
        div {
            cursor: pointer;
            &:hover {
                color: colors.$alt-link-hover;
            }
        }
    }
}

@media (max-width: 430px) {
    .message-box {
        height: 42rem;
        width: 25rem;
    }
}