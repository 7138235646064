//@use light theme colors 
@use "../../Light.scss" as colors;

.header-widget-action {
    display: flex;
    align-items: center;
    position: relative;
    svg {
        margin: 0 auto;
        display: block;
        cursor: pointer;
    }
    .header-list-widget-container {
        display: none;
        background-color: colors.$content-background;
        border: 1px solid black;
        border-radius: colors.$content-card-border-round;
        position: absolute;
        top: 125%;
        right: 0;
        z-index: 1;
        width: 300px;
        flex-direction: column;
        &.active {
            display: flex;
        }
        .list-widget-header {
            font-size: 24px;
            font-weight: 700;
            text-align: start;
            padding: 10px;
        }
        .list-widget-content {
            height: 20rem;
            max-height: 20rem;
            .header-list-widget-settings {
                display: flex;
                flex-direction: column;
                font-weight: 700;
                overflow: hidden;
                flex: 1;
                min-height: 0;
                height: 100%;
                a, div {
                    cursor: pointer;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    color: inherit;
                    border-bottom: solid 1px colors.$border-bottom;
                    &:last-child {
                        border-bottom: none;
                    }
                    &:hover {
                        background-color: colors.$highlight;
                        color: colors.$buttonsNbars;
                    }
                    &:first-child:hover {
                        border-top-left-radius: 16px;
                        border-top-right-radius: 16px;
                    }
                    &:last-child:hover {
                        border-bottom-left-radius: 16px;
                        border-bottom-right-radius: 16px;
                    }
                }
            }
        }
        .list-widget-footer {
            padding: 10px;
            border-top: 1px solid #e4e4e4;
            a {
                color: #00BCD4;
            }
        }
    }
}

.header-widget-action .header-list-widget-container .list-widget-content .header-list-widget-settings {
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
}

.content-scroll > div > .List::-webkit-scrollbar {
    display: none;
}

.content-scroll > div > .List {
    scrollbar-width: none;  /* Firefox */
}

.list-widget-friend-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-top: 1px solid #e4e4e4;
    &:hover {
        background-color: colors.$highlight;
    }
    .widget-friend-item-date {
        width: 100%;
        text-align: left;
        font-weight: 700;
        margin-left: 12px;
    }
    .widget-friend-item-content {
        display: grid;
        grid-template-columns: 0.25fr 1fr;
        grid-template-rows: 1fr;
        font-size: 14px;
        .widget-friend-item-profile {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.25rem;
            img {
                border-radius: 50%;
                width: 60px;
                height: 60px;
            }
        }
        .widget-friend-item-desc {
            text-align: start;
            margin-left: 0.5rem;
            width: 95%;
            margin-bottom: 0.5rem;
            div {
                a {
                    font-weight: 700;
                    color: #000000;
                }
                a:hover {
                    color: #0d6efd;
                }
            }
        }
        .widget-friend-item-actions {
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 0.33rem;
            button {
                margin-left: -18px;
                width: 100px;
                border-radius: 1rem;
            }
        }
    }
}

.widget-notification-item {
    display: grid;
    grid-template-columns: 0.25fr 1fr 0.4fr;
    border-top: 1px solid #e4e4e4;
    color: inherit;
    text-decoration: none;
    &:hover {
        background-color: colors.$highlight;
        color: inherit;
    }
    .widget-notification-item-image {
        width: 50px;
        height: 50px;
        display: flex;
        margin-top: 5px;
        margin-left: 5px;
        img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            align-self: center;
        }
    }
    .widget-notification-item-content {
        display: flex;
        flex-direction: column;
        width: 125%;
        height: 100%;
        text-align: start;
        margin-top: 5px;
        margin-left: 10px;
        font-size: 0.8rem;
    }
    .widget-notification-item-actions {
        display: flex;
        button {
            width: 100%;
            font-size: 0.8rem;
            align-self: center;
            margin-left: 10px;
        }
    }
}

.widget-message-item {
    display: grid;
    width: 100%;
    border-top: solid 1px #e4e4e4;
    grid-template-columns: 0.25fr 1fr 0.4fr;
    cursor: pointer;
    &:hover {
        background-color: colors.$highlight;
        // .widget-message-item-actions {
        //     display: flex;
        // }
    }
    .widget-message-item-image {
        width: 100%;
        height: 100%;
        display: flex;
        margin-left: 5px;
        img {
            border-radius: 50%;
            width: 100%;
            align-self: center;
        }
    }
    .widget-message-item-content {
        display: flex;
        flex-direction: column;
        width: 140%;
        height: 100%;
        font-size: 0.8rem;
        align-content: center;
        justify-content: center;
        padding-left: 0.2rem;
        div {
            text-align: left;
            margin-left: 10px;
            font-size: 14px;
        } 
        .widget-message-item-content-name {
            font-weight: 700;
            font-size: 14px;
        }
    }
    .widget-message-item-actions {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .widget-message-item-actions-icon {
            width: 1.5rem;
            height: 1.5rem;
            background-color: colors.$buttonsNbars;
            border-radius: 50%;
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            svg {
                width: 80%;
                height: 80%;
                align-self: center;
                margin: auto;
            }
            &:hover {
                background-color: white;
            }
            .widget-message-item-actions-menu {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 900;
                background-color: colors.$content-background;
                border-radius: colors.$content-card-border-round;
                box-shadow: 0 0 0.5rem 0.1rem rgba(0,0,0,0.2);
            }
        }
    }
}

.header-widget-icon {
    position: relative;
}

.count-badge {
    position: absolute;
    top: 0px;
    right: 0px;
    color: white;
    background-color: #fa3e3e;
    padding: 0px 6px;
    border-radius: 10px;
    font-size: 0.75rem;
    transform: scale(1) translate(100%, -100%);
    transform-origin: 100% 0% 0px;
}

// Footer stylings
.friend-requests-footer {
    display: flex;
    justify-content: center;
}

.widget-notifications-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    a {
        text-decoration: none;
        color: inherit;
        &:hover {
            color: colors.$buttonsNbars;
        }
    }
    span {
        color: inherit;
        &:hover {
            color: colors.$buttonsNbars;
        }
        cursor: pointer;
    }
}

@media only screen and (max-width: 430px) {
    .list-widget-friend-item .widget-friend-item-content .widget-friend-item-actions {
        margin-top: 5px;
        width: 100% !important;
    }
}

@media only screen and (max-width: 767px) {
    .list-widget-friend-item .widget-friend-item-date {
        margin-left: 50px;
    }

    .header-widget-action .header-list-widget-container {
        position: fixed;
        top: 13%;
        left: 3.5%;
        width: 93%;
    }

    .list-widget-friend-item .widget-friend-item-content .widget-friend-item-actions {
        width: 50%;
    }

    .widget-message-item {
        align-content: center;
    }

    .widget-message-item .widget-message-item-image {
        height: 65px;
        width: 65px;
    }
}