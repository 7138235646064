//@use light color scheme 
@use "../../Light.scss" as colors;

.notifications-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 1200px;
    height: 90%;
    margin-top: 10px;
    min-height: 75%;
    max-height: 90%;
    border-radius: 15px;
    .notifications-section {
        background-color: colors.$content-background;
        border-radius: 16px;
        .notifications-header {
            text-align: left;
            font-size: 1.5rem;
            font-weight: 700;
            padding: 8px;
        }
        .notifications-content {
            background-color: colors.$content-background;
            height: 90%;
            .notifications-content-message {
                text-align: center;
                width: 100%;
                margin-top: auto;
                margin-bottom: auto;
            }
            .notification-item {
                display: grid;
                grid-template-columns: 0.25fr .75fr 0.25fr 0.25fr;
                grid-template-rows: 1fr;
                width: 100%;
                height: 100%;
                border-bottom: 1px solid #e4e4e4;
                color: colors.$default-text;
                text-decoration: none;
                position: relative;
                .notification-item-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    img {
                        width: 72px;
                        height: 72px;
                        border-radius: 50%;
                    }
                }
                .notification-item-content {
                    display: flex;
                    align-items: center;
                }
                .MonthDate {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 700;
                }
            }
        }
    }
}

.notifications-buttons {
    display: flex;
    justify-content: center;
    column-gap: 8px;
    margin-bottom: 15px;
    button {
        background-color: colors.$buttonsNbars;
        color: colors.$buttonsNbarsText;
        border: none;
        border-radius: 16px;
        padding: 8px;
        font-size: 1.25rem;
        font-weight: 600;
    }
}

.notifications-container .notifications-section {
    width: 100%;
    height: 100%;
}

// Hide scrollbar for Chrome and Safari
.notifications-container .notifications-section > .notifications-content > div > .List::-webkit-scrollbar {
    display: none;
}

// Hide scrollbar for Firefox
.notifications-container .notifications-section > .notifications-content > div > .List {
    scrollbar-width: none;
}

.notification-item-wrapper {
    position: relative;
    height: 100%;
    &:hover {
        background-color: colors.$highlight;
        .notification-item-actions {
            .notification-item-action-more {
                display: block;
            }
        }
    }
    .notification-item-actions {
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        height: 100%;
        right: 20px;
        z-index: 900;
        .notification-item-action-status {
            display: block;
            height: 16px;
            width: 16px;
            line-height: 16px;
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 25px;
            svg.read {
                fill: colors.$highlight;
                color: colors.$highlight;
            }
            svg.unread {
                fill: colors.$alt-link-hover;
                color: colors.$alt-link-hover;
            }
        }
        .notification-item-action-more {
            display: block;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 1.3rem;
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
            background-color: colors.$buttonsNbars;
            color: colors.$buttonsNbarsText;
            cursor: pointer;
            position: relative;
            .notification-menu {
                display: none;
                &.show {
                    display: flex;
                }
                flex-direction: column;
                width: max-content;
                position: absolute;
                z-index: 901;
                right: 32px;
                background-color: colors.$content-background;
                border: black 1px solid;
                border-radius: 7px;
                padding-top: 8%;
                padding-bottom: 8px;
                .notification-menu-action {
                    color: colors.$default-text;
                    font-size: 0.9rem;
                    width: 100%;
                    padding-left: 8px;
                    padding-right: 8px;
                    &:hover {
                        background-color: colors.$highlight;
                    }
                }
            }
        }
    }
}



.notification-menu.show {
    animation: 0.3s fadein;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

@keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

// Media queries
@media only screen and (max-width: 834px) {
    // .notifications-container .notifications-section .notifications-content .notification-item {
    //     grid-template-columns: 0.12fr 0.40fr 0.25fr 0.15fr !important;
    // }

    .notifications-container .notifications-section .notifications-content .notification-item .notification-item-content {
        margin-left: 10px;
    }

    .notification-item > .MonthDate {
        font-size: 13px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .notifications-container {
        width: 99%;
    }

    .notifications-container .notifications-section .notifications-content .notification-item {
        grid-template-columns: 0.2fr 0.50fr 0.2fr 0.2fr;
    }

    .notifications-container .notifications-section .notifications-content .notification-item .notification-item-image img {
        width: 60px;
        height: 60px;
        margin-left: 4px;
    }

    .notification-item > .MonthDate {
        font-size: 14px;
    }
}

@media (min-width: 1025px) {
    .notifications-container .notifications-section {
        margin-left: auto;
        margin-right: auto;
        width: 60%;
    }
}

@media (max-width: 1920px),
       (max-height: 1080px) {
        .notifications-container .notifications-section {
            height: 60%;
        }
        }