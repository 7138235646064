//@use light color theme 
@use "../../Light.scss" as colors; 

.search-view-container {
    height: 100%;
    width: 100%;
    .search-view-wrapper {
        height: 95%;
        width: 75%;
        margin: auto;
        margin-top: 1rem;
        background-color: colors.$content-background;
        // border: 2px solid rgb(173, 173, 173);
        border-radius: 1rem;
        display: grid;
        grid-template-rows: 0.05fr 1fr 0.05fr;
        .search-view-header {
            background-color: colors.$buttonsNbars;
            border-radius: 1rem 1rem 0 0;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            h4 {
                color: colors.$buttonsNbarsText;
                margin-left: 1rem;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 1.5rem;
            }
            form {
                justify-self: center;
                width: 100%;
            }
        }
        .search-view-footer {
            background-color: colors.$buttonsNbars;
            border-radius: 0 0 1rem 1rem;
        }
        .search-view-content {
            height: 100%;
            width: 100%;
            display: grid;
            grid-template-columns: 0.45fr 1fr;
            .search-view-categories {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 0.5rem;
                .search-category-item {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 0.2fr 1fr;
                    column-gap: 1rem;
                    align-items: center;
                    padding: 1rem;
                    font-size: 1.2rem;
                    cursor: pointer;
                    svg {
                        font-size: 1.4rem;
                        border-radius: 50%;
                        padding: 0.5rem;
                        height: 2rem;
                        width: 2rem;
                        background-color: colors.$highlight;
                    }
                    &:hover {
                        background-color: colors.$highlight;
                        svg {
                            background-color: colors.$content-background;
                        }
                    }
                    p {
                        margin: 0;
                        padding: 0;
                    }
                    &.active {
                        background-color: colors.$highlight;
                        svg {
                            background-color: colors.$content-background;
                        }
                    }
                }
            }
            .search-view-list {
                height: 100%;
                width: 100%;
            }
            .search-view-list-wrapper {
                padding: 0.5rem;
                border-left: 1px solid black;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.search-view-list-item {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    cursor: pointer;
    border-radius: 0.7rem;
    &:hover {
        background-color: colors.$highlight;
    }
    .search-view-list-item-image {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 4rem;
            height: 4rem;
            display: block;
            border-radius: 50%;
        }
    }

    .search-view-list-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
            font-size: 1.1rem;
            padding: 0;
            margin: 0;
        }
        .search-view-list-item-content-type {
            color: rgb(0, 0, 0, 0.6);
            font-size: 0.8rem;
        }
    }
}