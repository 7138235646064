//@use light color theme 
@use "../../Light.scss" as colors;

.browse-videos-container {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    height: 100%;
    width: 100%;
    min-height: 50vh;
    margin-top: 1rem;
}

.videos-browse-list-item {
    display: grid;
    grid-template-rows: 0.9fr 0.1fr;
    max-height: 100%;
    max-width: 100%;
    background-color: colors.$content-background;
    padding: 0.5rem;
    border-radius: 7px;
    .video-item-thumbnail {
        cursor: pointer;
        width: 100%;
        height: 100%;
        max-height: 100%;
        background-position: center;
    }
    .video-item-description {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .video-item-title {
            width: 75%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.browse-videos-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 1rem;
    .browse-videos-sidebar-post-video {
        background-color: colors.$content-background;
        border-radius: 7px;
        padding: 0.75rem;
        display: flex;
        justify-content: center;
        a {
            background-color: colors.$buttonsNbars;
            color: colors.$buttonsNbarsText;
            padding: 1rem;
            border-radius: 7px;
        }
    }
    .browse-videos-sidebar-search {
        background-color: colors.$content-background;
        padding: 0.75rem;
        border-radius: 7px;
        .videos-search-header {
            font-size: 1.2rem;
        }
    }
}

.video-item-modal-body {
    min-height: 700px;
    display: grid;
    grid-template-columns: 1fr;
    .video-item-modal-video {
        width: 100%;
        height: 100%;
        iframe {
            height: 100%;
            width: 100%;
        }
    }
}