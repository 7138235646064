//@use light theme colors 
@use "../../Light.scss" as colors;

.upload-cover-photo-btn {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    position: absolute; 
    background-color: colors.$buttonsNbars; 
    color: colors.$buttonsNbarsText; 
    border-color: colors.$buttonsNbars; 
    border-radius: 50%; 
    width: 4rem; 
    height: 4rem; 
    cursor: pointer; 
    font-size: "2rem"; 
    bottom: 1rem; 
    right: 1rem;
    &:hover {
        background-color: colors.$buttonsNBarsDarkHover;
        border-color: colors.$buttonsNBarsDarkHover;
    }
}

.upload-cover-photo-preview {
    max-width: 100%; 
    max-height: 100%; 
    margin-top: 0.5rem;
}

// Media Queries
@media only screen and (max-width: 430px) {
    .upload-cover-photo-btn {
        width: 2rem;
        height: 2rem;
        top: 10%;
    }
}