.subscriptions-container {
    .subscriptions-current-information {
        margin-bottom: 1rem;
        .subscriptions-current-information-header {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
            font-weight: 600;
        }
        p {
            margin: 0;
            padding: 0;
        }
    }
    .subscription-options {
        .subscription-options-header {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
            font-weight: 600;
        }
    }
    .subscription-actions {
        margin-top: 1rem;
        button {
            border-radius: 16px;
        }
    }
}