//@use light theme colors 
@use "../../Light.scss" as colors;

//Styles for My Pages component 
.my-pages-container {
    width: 100%;
    height: 100%;
    min-height: 75vh;
    max-height: 75vh;
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    column-gap: 2rem;
    margin-top: 15px;
}

.my-pages-list-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    // background-color: colors.$content-background;
    border-radius: colors.$content-card-border-round;
    .my-pages-list-item {
        display: grid;
        grid-template-columns: 0.25fr 1fr;
        background-color: colors.$content-background;
        max-height: 100%;
        padding: 0 0 0 10px;
        align-items: center;
        .my-pages-item-img-container {
            display: flex;
            width: 140px;
            height: 140px;
            border: 2px solid #f2f0f0;
            border-radius: 50%;
            img {
                display: block;
                max-height: 100%;
                max-width: 100%;
                border-radius: 50%;
                width: 140px;
                height: 140px;
            }
        }
        .my-pages-item-content-container {
            display: flex;
            flex-direction: column;
            height: inherit;
            justify-content: flex-start;
            margin-left: 15px;
            justify-content: center;
            .my-pages-item-content-actions{
                display: flex;
                justify-content: flex-start;
                column-gap: 0.5rem;
                a {
                    text-decoration: none;
                    color: inherit;
                    cursor: pointer;
                    display: inline;
                    &:nth-child(1) {
                        margin-right: 1rem;
                    }
                    :nth-child(1) {
                        margin-right: 0.5rem;
                    }
                    &:hover {
                        color: colors.$buttonsNbars;
                    }
                }
                span {
                    &:hover {
                        color: colors.$buttonsNbars;
                    }
                    svg {
                        margin-right: 0.5rem;
                    }
                    cursor: pointer;
                }
            }
            .my-pages-item-content-name {
                a {
                    text-decoration: none;
                    color: colors.$default-text;
                    font-weight: 600;
                    font-size: 1.25rem;
                    &:hover {
                        color: colors.$buttonsNbars;
                    }
                } 
            }
            .my-pages-item-content-stats {
                p {
                    padding: 0;
                    margin: 0;
                }

                // .item-content-stats-expiration {
                //     color: green;
                // }
            }
            .my-pages-item-content-desc {
                white-space: pre-line;
            }
        }
    }
}

.my-pages-list-wrapper .my-pages-list-item:nth-child(odd) {
    background-color: #fbfbfb;
}

.my-pages-list-wrapper .my-pages-list-item:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.my-pages-list-wrapper .my-pages-list-item:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.my-pages-sidebar {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
    width: 100%;
    .my-pages-other-container {
        background-color: colors.$content-background;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 16px;
        a {
            text-decoration: none;
            color: colors.$buttonsNbarsText;
            background-color: colors.$buttonsNbars;
            padding: 0.5rem 1rem;
            border-radius: colors.$content-card-border-round;
        }
    }
    .my-pages-create-container {
        background-color: colors.$content-background;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 16px;
        a {
            text-decoration: none;
            color: colors.$buttonsNbarsText;
            background-color: colors.$buttonsNbars;
            padding: 0.5rem 1rem;
            border-radius: colors.$content-card-border-round;
        }
    }
}

@media only screen and (max-width: 767px) {
    .my-pages-list-wrapper .my-pages-list-item .my-pages-item-img-container {
        width: 105px;
        height: 105px;
    }

    // .my-pages-list-wrapper .my-pages-list-item .my-pages-item-content-container {
    //     display: block;
    // }

    .my-pages-container {
        display: flex;
    }

    .my-pages-sidebar {
        display: none;
    }
}

@media (max-width: 430px) {
    .my-pages-list-wrapper .my-pages-list-item {
        padding: 0;
    }

    .my-pages-list-wrapper .my-pages-list-item .my-pages-item-content-container {
        margin-left: 0.5rem;
        width: 97%;
    }

    .my-pages-list-wrapper .my-pages-list-item .my-pages-item-content-container .my-pages-item-content-name a {
        font-size: 1.10rem;
    }

    .my-pages-item-content-stats {
        font-size: 0.95rem;
        font-weight: 600;
    }

    .my-pages-item-content-desc {
        font-size: 0.90rem;
    }
}

@media (max-width: 820px) {
    .my-pages-container {
        grid-template-columns: 1fr;
        width: 97%;
        margin-left: auto;
        margin-right: auto;
    }

    .my-pages-list-wrapper .my-pages-list-item .my-pages-item-img-container {
        width: 90px !important;
        height: 90px !important;
    }

    .my-pages-sidebar {
        display: none;
    }
}

@media (max-width: 1024px) {
    .my-pages-list-wrapper .my-pages-list-item .my-pages-item-img-container {
        width: 120px;
        height: 120px;
    }

    
}