//@use light color scheme 
@use "../../Light.scss" as colors;


.event-view-container {
    display: grid;
    grid-template-rows: 0.5fr 1fr;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.event-view-header-container{
    height: 100%;
    background: linear-gradient(to left, #00BCD4, #eef2f3);
    position: relative;
    .event-view-header-content {
        position: absolute;
        bottom: 8px;
        left: 8px;
        width: 100%;
        height: 50%;
        display: flex;
        column-gap: 1rem;
        img {
            max-width: 100%;
            height: 100%;
        }
        .event-view-header-content-title {
            color: colors.$buttonsNbarsText;
            font-size: 1.3rem;
            white-space: nowrap;
        }
        .event-view-header-content-actions {
            position: relative;
            width: 100%;
            .event-view-header-content-actions-button {
                color: colors.$buttonsNbarsText;
                svg {
                    cursor: pointer;
                    &:hover {
                        color: colors.$buttonsNbars;
                    }
                }
            }
            .event-view-header-content-actions-menu {
                position: absolute;
                display: none;
                background-color: colors.$content-background;
                border-radius: colors.$content-card-border-round;
                &.show {
                    display: flex;
                    flex-direction: column;
                    row-gap: 0.1rem;
                }
                div {
                    padding: 8px;
                    &:hover {
                        background-color: colors.$highlight;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.event-view-content {
    display: grid;
    grid-template-rows: 0.1fr 1fr;
    row-gap: 1rem;
    margin-top: 16px;
    .event-view-content-nav {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 1rem;
        background-color: colors.$content-background;
        border-radius: colors.$content-card-border-round;
        padding-left: 16px;
        padding-right: 16px;
        .event-nav-item {
            padding: 0 8px;
            font-size: 1.1rem;
            cursor: pointer;
            &.event-active-nav {
                color: colors.$buttonsNbars;
                font-weight: 700;
            }
        }
    }
    .event-view-content-wrapper {
        display: grid;
        grid-template-columns: 0.2fr 0.6fr 0.2fr;
        column-gap: 1rem;
        .event-view-content-main {
            color: black;
        }
        .event-sidebar-container {
            .event-sidebar-item {
                padding: 16px;
                border-radius: colors.$content-card-border-round;
                background-color: colors.$content-background;
                .event-sidebar-header {
                    font-size: 1.25rem;
                    font-weight: 700;
                    width: 100%;
                    border-bottom: 1px solid colors.$highlight;
                    padding-bottom: 8px;
                }
            }
        }
    }
}

.event-sidebar-actions {
    div {
        padding: 8px;
        font-weight: bold;
        cursor: pointer;
        border-bottom: 1px solid colors.$highlight;
        a {
            text-decoration: none;
            color: inherit;
        }
        &:hover {
            color: colors.$buttonsNbars;
        }
    }
}
.event-sidebar-description {
    margin: 10px 0;
}

.event-guests-list-toggles {
    display: flex;
    justify-content: space-evenly; 
    background-color: colors.$content-background; 
    border-radius: colors.$content-card-border-round; 
    padding: 0.2rem; 

    div {
        color: colors.$default-text; 
        cursor: pointer; 
        font-weight: bold; 
        &:hover {
            color: colors.$hover; 
        }
        &.active{
            color: colors.$hover; 
        }
    }
}

.event-guests-grid-item {
    display: grid;
    grid-template-columns: 0.2fr 0.8fr;
    background-color: colors.$content-background;
    border-radius: colors.$content-card-border-round;
    padding: 8px;
    width: 97% !important;
    a img {
        display: flex;
        justify-content: center;
        max-width: 72px;
        height: 72px;
        margin: auto;
        border-radius: 50%;
    }
    .grid-item-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .grid-item-title {
            font-weight: bold;
            cursor: pointer;
            &:hover {
                color: colors.$buttonsNbars;
            }
        }
        a{
            text-decoration: none;
            color: colors.$default-text;
        }
        .grid-item-sub-text {
            font-size: 0.8rem;
            font-weight: 500;
            color: colors.$subtext;
        }
    }
}

.event-photos-grid-item {
    display: grid;
    grid-template-rows: 0.8fr 0.2fr;
    background-color: colors.$content-background;
    border-radius: colors.$content-card-border-round;
    padding: 8px;
    img {
        max-width: 100%;
        height: 100%;
        margin: auto;
    }
    .grid-item-text {
    }
}

.event-view-content-main {
    .posts-feed {
        margin-top: -9px;
    }
}

// Media quieries
@media (max-width: 540px) {
    .event-guests-grid-item .grid-item-text {
        margin-left: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .event-view-container {
        grid-template-rows: 0.25fr 0.75fr;
        height: 900px;
    }
    
    .event-view-header-container {
        height: 160px;
    }

    // .event-view-content-wrapper {
    //     display: flex;
    //     flex-direction: column;
    // }

    .event-view-content .event-view-content-nav {
        justify-content: space-around;
        padding: 16px;
    }

    .event-sidebar-container {
        // display: flex;
        // flex-direction: column;
    }

    .event-details-description {
        order: 1;
    }

    .event-page-actions {
        order: 2;
    }

    .event-view-content-main {
        order: 3;
        height: 480px;
        margin-top: 10px;
        width: 205%;
    }

    .event-view-content .event-view-content-wrapper .event-sidebar-container {
        margin-top: 0;
    }

    .event-guests-grid-item {
        width: 95% !important;
    }

    .event-view-content .event-view-content-wrapper {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 1200px) {
    .event-view-container {
        width: 1200px;
    }
}