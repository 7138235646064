//@use light color theme
@use "../../Light.scss" as colors;

// Styles for Advertisement Layout Component
.advertise-container {
    display: grid;
    grid-template-rows: 0.1fr 0.9fr;
    width: 95%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    .advertise-nav {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 16px;
        background-color: colors.$content-background;
        border-radius: colors.$content-card-border-round;
        padding: 16px;
        margin-top: 16px;
        white-space: nowrap;
        .advertise-nav-header {
            font-size: 2rem;
            font-weight: 700;
        }
        .advertise-nav-item {
            height: 100%;
            display: inline;
            margin-right: 16px;
            color: black;
            font-size: 1.5rem;
            font-weight: 600;
            &:hover {
                color: colors.$alt-link-hover;
            }
            &.active {
                color: colors.$alt-link-hover;
            }
        }
    }
    .advertisement-package-price {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 24px 0;
    }
    .advertisement-package-create-btn {
        border-radius: 16px;
    }
}

// Media Queries
@media only screen and (max-width: 768px) {
    .advertise-nav-header {
        display: flex !important;
        flex-direction: column !important;
        .advertise-nav-item  {
            width: 10% !important;
        }
    }
}

@media only screen and (max-width: 920px) {
    .advertise-nav {
        height: 100% !important;
    }

    .advertise-content-box {
        margin-top: 2rem !important;
    }

    .advertise-container .advertise-nav {
        flex-direction: column;
        gap: 0;
    }

    .advertise-container .advertise-nav .advertise-nav-header {
        font-size: 1.5rem;
    }

    .advertise-container .advertise-nav .advertise-nav-item {
        font-size: 1rem;
    }

    .advertise-create-choose-package {
        display: flex !important;
        flex-direction: column !important;
    }
}

@media only screen and (min-width: 1200px) {
    .advertise-container {
        width: 1200px;
    }
}