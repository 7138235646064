@use "../../Light.scss" as colors; 

.group-edit-container {
    .group-edit-form-container {
        background-color: colors.$content-background;
        border-radius: 7px;
        padding: 1rem;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.group-edit-form-photo-preview {
    width: 10rem;
    height: 10rem;
    display: block;
    border: 1px solid black;
    margin-top: 1rem;
}