//Import light color scheme 
@use "../../Light.scss" as colors;

.signup-container {
    margin: auto;
    margin-top: 1rem;
    width: 90%;
    height: 75%;
}

.signup-welcome {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
}

.signup-form-error {
    color: red;
}

.signup-form-wrapper {
    background-color: colors.$content-background;
    padding: 0.5rem;
    border-radius: 15px;
    margin-bottom: 25px;
    .signup-form-header {
        .signup-form-header-text {
            font-size: 1.75rem;
            color: colors.$default-text;
            font-weight: 700;
            text-align: center;
            margin-bottom: 1rem;
        }
        div {
            border-bottom: 1px solid colors.$highlight;
        }
    }
    .signup-form {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        .mb-3 {
            .form-label {
                font-size: 1.1rem;
                font-weight: 600;
            }
            .form-control {
                border-radius: 15px;
            }
        }
        .signup-form-submit-btn {
            display: flex;
            justify-content: center;
            margin: 25px 0;
            button {
                font-size: 1.25rem;
                font-weight: 600;
                background-color: #27A9BE;
                border-color: #27A9BE;
                border-radius: 15px;
            }
        }
    }
}

.signup-form-header-signin {
    color: colors.$default-text;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    text-align: center;
    a {
        color: #27A9BE;
    }
}

.mb-3 {
    .signup-form-picture-preview {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        height: 50%;
        border: 1px solid colors.$highlight;
        margin-top: 1rem;
    }
}

@media only screen and (max-width: 430px) {
    .signup-welcome {
        font-size: 1rem;
        text-align: left;
    }
}

@media only screen and (min-width: 980px) {
    .signup-container {
        width: 960px;
    }
}