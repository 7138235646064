@use "../../Light.scss" as colors;

// Styles file for Groups View Layout

.groups-view-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    row-gap: 1rem;
}

.groups-view-wrapper {
    display: grid;
    grid-template-columns: 0.225fr 1fr;
    column-gap: 1rem;
    width: 100%;
    row-gap: 1rem;
    margin-left: auto;
    margin-right: auto;
}

//Group header
.groups-header {
    background-color: colors.$content-background;
    border-radius: 1rem;
}

.groups-header-wrapper {
    height: 300px;
    margin: 1rem;
    background-color: colors.$content-background;
    position: relative;
}

.groups-header-coverphoto {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.groups-image-container {
    width: 140px;
    height: 140px;
    position: absolute;
    background-color: colors.$content-background;
    padding: 0.3rem;
    bottom: 1rem;
    left: 1rem;
    border: 1px solid grey; //remove?
}

.groups-image-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.groups-header-title {
    position: absolute;
    bottom: 3.5rem;
    left: 10.5rem;
}

.groups-header-title h4 {
    color: colors.$highlight;
    font-size: 2rem;
    font-weight: 700;
    text-shadow: black 1px 0 10px;
}

.groups-view-content-container {
    display: grid;
    grid-template-columns: 0.27fr 1fr;
    column-gap: 1rem;
    height: 100%;
    .groups-view-sidebar {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        row-gap: 1rem;
        .groups-view-sidebar-item {
            display: flex;
            background-color: colors.$content-background;
            border-radius: 16px;
            padding: 1rem;
            height: fit-content;
            .groups-sidebar-actions {
                padding-bottom: 1rem;
                .groups-sidebar-actions-item {
                    font-weight: 600;
                    padding-top: 0.5rem;
                    cursor: pointer;
                }
                .groups-sidebar-actions-item:hover {
                    color: colors.$alt-link-hover;
                }
                .groups-sidebar-actions-item>span {
                    margin-left: 0.5rem;
                }
                .groups-sidebar-actions-item>a {
                    margin-left: 0.5rem;
                    color: inherit;
                    text-decoration: none;
                }
            }
            .groups-sidebar-info {
                #groups-sidebar-info-title {
                    font-weight: bold;
                }
                #groups-sidebar-info-category {
                    font-size: 0.7rem;
                }
                #groups-sidebar-info-desc {
                    font-size: 0.8rem;
                    margin-bottom: 0.5rem;
                }
                #groups-sidebar-info-owner {
                    font-weight: 600;
                    a {
                        color: #000000;
                    }
                    a:hover {
                        color: #00bcd4;
                    }
                }
                .groups-sidebar-info-stats {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    span {
                        font-size: 0.85rem;
                    }
                }
            }
        }
    }
    .group-view-content-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        .group-view-content {
            flex: 1 1 auto;
        }
    }
}

// Media query
@media only screen and (max-width: 767px) {
    .groups-view-content-container {
        grid-template-columns: 1fr;
        .groups-view-sidebar {
            display: none;
        }
        .group-view-content-wrapper {
            margin-left: auto;
            margin-right: auto;
            width: 99%;
            height: 800px;
        }
    }
}