//@use light color scheme 
@use "../../../../Light.scss" as colors; 


.page-edit-manage-admins-container {
    flex: 0.8;
    .page-edit-manage-admins-content {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 720px;
        .page-edit-manage-admins-user-list {
            border: 1px solid black;
            width: 100%;
            height: 100%;
            // display: grid;
            // grid-template-columns: 0.5fr 0.5fr;
            // grid-template-rows: 0.5fr 0.5fr;
            overflow-y: auto;
            .admin-user-item {
                display: grid;
                grid-template-columns: 0.4fr 1fr 0.35fr 0.25fr;
                height: 5rem;
                margin: 1rem;
                background-color: colors.$highlight;
                border-radius: colors.$content-card-border-round;
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .admin-user-item-avatar {
                    img {
                        border-radius: 50%;
                        width: 100%;
                    }
                }
                .admin-user-item-name {
                    justify-content: flex-start;
                }
                .admin-user-item-role {
                    justify-content: flex-start;
                }
                .admin-user-item-actions {
                    .admin-user-item-actions-button {
                        background-color: colors.$buttonsNbars;
                        border-radius: 50%;
                        padding: 0.5rem;
                        cursor: pointer;
                        position: relative;
                        .admin-user-item-action-menu {
                            display: none;
                            &.show-menu {
                                display: flex;
                            }
                            flex-direction: column;
                            width: 10rem;
                            position: absolute;
                            top: 100%;
                            right: 0;
                            background-color: colors.$content-background;
                            border: 1px solid black;
                            border-radius: colors.$content-card-border-round;
                            .admin-user-item-action-menu-item {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0.5rem;
                                cursor: pointer;
                                &:hover {
                                    background-color: colors.$highlight;
                                }
                            }
                        }
                    }
                }
            }
        }
        .page-edit-manage-admins-add-container {
            display: grid;
            grid-template-columns: 0.6fr 0.3fr 0.2fr;
            height: 1rem;
        }
    }
}