//Import Light color scheme 
@use "../../Light.scss" as colors;

.video-display-component {
    width: 100%;
    height: 100%;
    .video-display-component-video {
        display: grid;
        grid-template-columns: 80% 20%;
        width: 100%;
        height: 100%;
        .video-display-wrapper {
            display: flex;
            flex-direction: column;
            background-color: black;
            position: relative;
            .zoom-in-out-buttons {
                display: flex;
                justify-content: flex-end;
                margin-right: 1rem;
            }
            video {
                width: 100%;
                height: 91vh;
                margin-top: auto;
                margin-bottom: auto;
            }
            iframe {
                width: 100%;
                height: 100%;
                margin-top: auto;
                margin-bottom: auto;
            }
            img {
                margin: auto;
                max-height: 91vh;
                max-width: 100%;
                width: 100%;
            }
            .display-item-count {
                color: white;
                padding: 0 0 0 5px;
            }
            .display-wrapper-nav {
                display: flex;
                width: 100%;
                top: 50%;
                justify-content: space-between;
                padding: 1rem;
                position: absolute;
                font-size: 2rem;
                color: white;
                z-index: 9999;
                svg {
                    cursor: pointer;
                }
            }
        }
        .video-info {
            background-color: white;
            padding: 0.5rem;
            width: 100%;
            height: 100%;
            max-height: 100%;
            overflow-x: auto;
            padding-top: 1rem;
            position: relative;
            .video-stats {
                padding-bottom: 0.5rem;
                .likes-comments-views {
                    padding-bottom: 0.25rem;
                }
                .ratings {
                    display: flex;
                    column-gap: 0.5rem;
                    padding-bottom: 0.5rem;
                }
                border-bottom: 2px solid black;
            }
            .video-header {
                display: flex;
                .title {
                    font-size: 1.2rem;
                    font-weight: bold;
                }
                .ratings {
                    margin-left: auto;
                }
            }
            .video-description {
                inline-size: 100%;
                overflow-wrap: break-word;
                padding: 0 3px;
            }
            .video-actions {
                .comment-item {
                    .comment-item-body {
                        p {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.zoom-in-out-buttons {
    .zoom-and-reset-btn {
        background-color: colors.$button-color;
        border-radius: 0.5rem;
        color: white;
        border: none;
        font-size: 1.25rem;
        margin-right: 0.5rem;
        width: 2.25rem;
        height: 2.25rem;
    }
}

.react-transform-wrapper {
    margin: auto !important;
}

.video-actions > div > span {
    font-weight: 600;
    &:hover {
        text-decoration: underline;
    }
}

.star-rating-component {
    display: flex;
    align-items: center;
    .star-rating-gold {
        color: gold;
    }
    .star-rating-grey {
        color: grey;
    }
}

.video-owner {
    display: flex;
    padding-bottom: 1rem;
    .video-owner-image {
        border-radius: 50%;
        width: 48px;
        height: 48px;
    }
    .video-owner-name {
        font-weight: bold;
        font-size: 1.1rem;
        margin-left: 10px;
        a {
            text-decoration: none;
            color: colors.$default-text;
            &:hover {
                color: colors.$alt-link-hover;
            }
            cursor: pointer;
        }
        .title {
            font-weight: bold;
            font-size: 1rem;
            inline-size: 100%;
            overflow-wrap: break-word;
        }
    }
}

.video-display-dialog {
    .modal-content {
        background-color: black;
    }
}

.button-bar {
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0.5rem;
    svg {
        cursor: pointer;
        color: colors.$default-text;
        &:hover {
            color: colors.$alt-link-hover;
        }
    }
}

.video-actions .comment-create {
    padding: 1rem 0 1rem 0.25rem;
    border-bottom: 1px solid #e7e3e3;
}

.video-actions {
    .comment-create {
        .comment-create-text-box-container {
            textarea {
                width: 90%;
            }
        }
    }
}

.video-actions .comment-item {
    padding: 0;
    .comment-item-body {
        margin-left: 10px;
    }
}

// Media queries
@media only screen and (max-width: 430px) {
    .modal-backdrop {
        width: 100%;
        height: 100%;
    }

    .modal-fullscreen {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .video-display-component .video-display-component-video {
        display: flex !important;
        flex-direction: column !important;
    }

    .zoom-and-reset-btn {
        width: 2rem !important;
        height: 2rem !important;
    }

    .video-display-component .video-display-component-video .video-display-wrapper img {
        max-height: 50vh;
    }

    .video-display-component .video-display-component-video .video-display-wrapper video {
        height: 24vh;
    }
}

// @media only screen and (max-width: 1024px) {
//     .video-owner {
//         grid-template-columns: 20% 80%;
//     }
// }

@media only screen and (min-width: 1920px) {
    .video-actions .comment-create .comment-create-text-box-container textarea {
        width: 93%;
    }
}

@media (max-width: 1920px) {
    .video-display-component .video-display-component-video {
        grid-template-columns: 70% 30%;
    }
}