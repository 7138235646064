// @use light color scheme
@use "../../Light.scss" as colors;

.home-container {
    // display: flex;
    // height: 100%;
    // justify-content: space-evenly;
    display: grid;
    grid-template-columns: 0.2fr 0.6fr 0.2fr;
    height: 100%;
    width: 100%;
}

.home-newsfeed {
    max-width: 50%;
    padding: 0;
} 

.home-sidebar-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    width: 100%;
    position: relative;
}

.home-sidebar {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .home-sidebar-item {
        background-color: colors.$content-background;
        min-height: 12em;
        width: 100%;
        padding: 1rem;
        border-radius: 16px;
        box-shadow: 0 3px 7px 0 rgba(0,0,0,0.2);
    }
    .home-sidebar-item-sm {
        background-color: colors.$content-background;
        min-height: 6em;
        width: 100%;
        padding: 1rem;
        border-radius: 16px;
        box-shadow: 0 3px 7px 0 rgba(0,0,0,0.2);
    }
    .sidebar-profile-preview {
        font-size: 1.25rem;
        .profile-preview-header {
            border-bottom: 1px solid colors.$highlight;
            padding-bottom: 10px;
            font-weight: 600;
        }
        .profile-preview-img-container {
            text-align: center;
            margin-top: 10px;
        }
        img {
            max-width: 189px;
            height: 189px;
            object-fit: cover;
            border-radius: 50%;
            border: 1px solid #e4e4e4;
        }
        svg {
            max-width: 189px;
            height: 189px;
        }
    }
    .sidebar-donate {
        p {
            font-size: 1rem;
            font-weight: 600;
            padding: 0;
            margin: 0;
        }
        button {
            width: 100%;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            border-radius: 16px;
        }
    }
    .sidebar-quick-links {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 0.5rem;
        a {
            text-decoration: none;
            color: colors.$default-text;
            flex: 1;
            border-bottom: 1px solid colors.$highlight;
            &:hover {
                color: colors.$alt-link-hover;
            }
        }
        a:last-child {
            border-bottom: none;
        }
    }
    .sidebar-popular-pages {
        .sidebar-popular-pages-header {
            border-bottom: 1px solid colors.$highlight;
            margin-bottom: 10px;
            font-size: 1.1rem;
            font-weight: 600;
            padding-bottom: 10px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        .sidebar-popular-pages-item {
            display: grid;
            grid-template-columns: 0.3fr 0.8fr;
            max-width: 100%;
            column-gap: 0.5rem;
            margin-bottom: 5px;
            &:hover {
                background-color: colors.$highlight;
            }
            .sidebar-popular-pages-item-img {
                img {
                    width: 100%;
                    border-radius: 50%;
                    border: 1px solid #e4e4e4;
                }
            }
            .sidebar-popular-pages-item-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 0.8rem;
                color: colors.$default-text;
                p {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}

.date-filter-button {
    background-color: colors.$buttonsNbars;
    border: none;
    border-radius: 1rem;
    font-weight: 600;
    &:hover {
        background-color: colors.$buttonsNBarsDarkHover;
    }
}

.timeline-btn button {
    font-size: 1.25rem;
    font-weight: 600;
    border-radius: 1rem;
}

.sidebar-popular-pages-item-title {
    font-weight: 700;
}

.column-left {
    display: block;
}

.column-right {
    display: none;
}

.home-sidebar {
    flex: 0 0;
}

.sidebar-popular-pages {
    margin-left: auto !important;
}

// Media Queries

@media only screen and (min-width: 375px) {
    .post-item-info {
        margin-left: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .home-container {
        grid-template-columns: 1fr !important;
    }

    .home-news-feed-container {
        margin-right: auto !important;
    }

    .layout-left {
        display: none;
    }

    .home-sidebar-container {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .right-column-left {
        display: block;
    }

    .post-item-info {
        margin-left: 15px;
    }
}

@media only screen and (min-width: 900px) {
    .post-item-info {
        margin-left: 5px;
    }
}

@media only screen and (max-width: 928px) {
    .home-sidebar .profile-preview-header {
        font-size: 1.1rem;
    }
}

@media only screen and (max-width: 945px) {
    .home-container {
        grid-template-columns: 0.25fr 0.6fr 0.1fr;
    }

    .home-sidebar .sidebar-profile-preview img {
        max-width: 140px;
        height: 140px;
    }
}

@media only screen and (min-width: 1080px) {
    .post-item-info {
        margin-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 820px) {
    .home-container {
        grid-template-columns: 0.3fr 0.75fr 0.1fr !important;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .home-container {
        height: 1600px;
    }
}

@media only screen and (max-width: 1199px) {
    .home-container {
        grid-template-columns: 0.25fr 0.8fr 0.1fr;
    }

    .home-sidebar-container {
        margin-left: 15px;
    }

    .home-news-feed-container {
        width: 95%;
        height: 100%;
        margin-left: auto;
        margin-right: 0;
    }
}

@media only screen and (min-width: 1200px) {
    .home-container {
        width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }

    .layout-left {
        padding: 0 8px 0 0;
    }

    .column-left {
        display: none;
    }

    .column-right {
        display: block;
        padding: 0 0 10px 8px;
    }

    .right-column-left {
        display: none;
    }
}