//XAPiT SCSS LIGHT COLOR SCHEME
$xapit-background: rgb(172,207,254);
$main-background: rgb(230,236,240);
$buttonsNbars: rgb(0,188,212);
$buttonsNBarsDarkHover: rgb(0,150,167);
$buttonsNbarsText: white;
$content-background: #FFFFFF;
$link-hover: black;
$default-text: black;
$alt-link-hover: rgb(0,188,212);
$highlight: #f2f2f2;
$hover: #00bcd4;
$border-all: #ced4da;
$border-all-alt: #e4e0e0;
$border-bottom: #e4e4e4;
$alt-border-bottom: #dadce0;
$subtext: #65676b;
$mute-text: darkgrey;
$timeline-slider: #fafafa;
$button-color: #2dbcd3;

//Default Measurements
$content-card-border-round: 16px;