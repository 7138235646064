@use "../Light.scss" as colors; 

// Footer Styles
.Footer-Container {
    background-color: colors.$content-background;
    width: 100%;
    justify-content: center;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
}