//Use light color theme 
@use "../../Light.scss" as colors; 

.sitepages-search-input-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    .sitepages-search-preview-container {
        display: none;
        position: absolute;
        z-index: 900;
        border: 1px solid black;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        left: -1rem;
        top: 100%;
        height: 20rem;
        background-color: colors.$content-background;
        .sitepages-search-preview-item {
            height: 75px;
            width: 100%;
            display: flex;
            align-items: center;
            &:hover {
                background-color: colors.$highlight;
            }
            .sitepages-search-preview-item-img {
                display: flex;
                align-items: center;
                height: 100%;
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }
            }
            .sitepages-search-preview-item-title {
                display: flex;
                flex-direction: column;
                margin-left: 0.75rem;
                width: 80%;
                strong {
                    color: colors.$default-text;
                    text-decoration: none;
                }
                .site-pages-search-item-description {
                    color: colors.$default-text;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
        &.show {
            display: block;
        }
    }
}