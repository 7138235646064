//@use light color theme 
@use "../../Light.scss" as colors;

.my-videos-container {
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    height: 100%;
    width: 100%;
    min-height: 50vh;
    margin-top: 1rem;
}

.my-videos-sidebar {
    display: flex;
    flex-direction: column;
    background-color: colors.$content-background;
    border-radius: 7px;
    padding: 1rem;
    row-gap: 0.5rem;
    margin-left: 1rem;
    max-height: 50%;
    .my-videos-sidebar-header {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .my-videos-sidebar-item {
        border-bottom: 1px solid colors.$default-text;
    }
}