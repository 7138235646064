//@use light theme colors 
@use "../../Light.scss" as colors;

.ads-widget-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: 1rem;
}   

.ads-widget-item {
    display: grid;
    grid-template-rows: 1rem, 1fr, 2rem;
    background-color: colors.$content-background;
    padding: 0.75rem;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
    a {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .ads-widget-item-title {
        margin-bottom: 5px;
        font-size: 1.1rem;
        font-weight: 500;
    }
    .ads-widget-item-image {
        max-width: 100%;
        border-radius: 16px;
    }
    .ads-widget-item-description {
        margin-top: 5px;
    }
}