// @use Light color scheme 
@use "../../../Light.scss" as colors;

.page-dashboard-container {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    width: 90%;
    margin: auto;
    background-color: colors.$content-background;
    border-radius: 7px;
    .page-dashboard-content-container {
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem 1rem 0.25rem;
        .page-dashboard-content-container-header {
            display: flex;
            justify-content: space-between;
        }
    }
}

.page-dashboard-nav {
    display: flex;
    flex-direction: column;
    padding: 15px;
    height: 100%;
    max-height: inherit;
    border-radius: 7px 0 0 7px;
    .page-dashboard-nav-category {
        font-size: 1.5rem;
        margin: 10px 0;
    }
    .page-dashboard-nav-links {
        display: flex;
        flex-direction: column;
        gap: 1px;
        .page-dashboard-nav-item {
            color: colors.$default-text;
            background-color: colors.$highlight;
            border-radius: 7px;
            padding: 0.5rem;
            font-size: 1.1rem;
            &:hover {
                color: colors.$buttonsNbars;
            }
            &.active {
                color: colors.$buttonsNbars;
            }
        }
    }
}

.info-row {
    display: block;
}

// Media Queries
@media only screen and (max-width: 767px) {
    .page-dashboard-container {
        display: block;
    }
}

@media only screen and (min-width: 1200px) {
    .page-dashboard-container {
        width: 1200px;
    }
}