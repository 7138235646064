//Featured Pages List Component

//@use light color scheme
@use "../../Light.scss" as colors;

.featured-pages-item {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    column-gap: 1rem;
    background-color: colors.$content-background;
    border-radius: 1rem;
    padding: 1rem;
    a {
        display: flex;
        align-items: center;
        overflow: hidden;
        img {
            max-width: 100%;
            max-height: 100%;
            height: 100px;
            width: 100px;
            object-fit: cover;
            overflow: hidden;
            border: 2px solid #f2f0f0;
            border-radius: 50%;
        }
    }
    .featured-pages-item-info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .featured-pages-item-info-title {
            font-size: 1.1rem;
            font-weight: 600;
            color: colors.$default-text;
            a {
                color: colors.$default-text;
                text-decoration: none;
                &:hover {
                    color: colors.$alt-link-hover;
                }
            }
        }
        .featured-pages-item-last-updated {
            font-weight: 500;
        }
        .featured-pages-item-info-date {
            font-size: 0.9rem;
            color: colors.$subtext;
        }
        .featured-pages-item-info-description {
            color: colors.$default-text
        }
    }
}

//Loading icon wrapper
.featured-pages-loading {
    display: flex;
    justify-content: center;
}

.featured-pages-nav {
    display: flex;
    justify-content: space-around;
    min-height: 45px;
    background-color: colors.$content-background;
    border-radius: 16px;
    .featured-pages-nav-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1px;
        font-size: 1.1rem;
        cursor: pointer;
        &.active {
            color: colors.$alt-link-hover;
            font-weight: 600;
        }
        &:hover {
            color: colors.$alt-link-hover;
        }
    }
}

.featured-pages-container {
    display: grid !important;
    grid-template-rows: 0.05fr 1fr !important;
    height: 100%;
    width: 100%;
}

@media (max-width: 390px) {
    .featured-pages-item a {
        align-items: center !important;
    }
}

@media (max-width: 430px) {
    .featured-pages-item a {
        align-items: center;
    }

    .featured-pages-item a img {
        height: 80px;
        width: 80px;
    }
}

@media only screen and (max-width: 920px) {
    .featured-pages-container {
        grid-template-rows: 1fr 25fr !important;
        width: 97%;
        margin-left: auto;
        margin-right: auto;
    }
}