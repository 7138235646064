//Color scheme
@use "Light"as colors;
//Footer Styles
@use "./base/Footer.scss";

@use "Mainv2.scss"as mainv2;

//Default Styles
html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: colors.$main-background;
}

#root {
    height: 100%;
}

//Link overrides
a:link,
a:hover,
a:visited,
a:active {
    text-decoration: none;
}

//Default-Layout-Container
.default-layout-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.16fr 1fr;
    min-height: 100%;
    height: 1280px;
}

.content-box {
    width: 100%;
    min-height: 100%;
    margin: 0 auto 0 auto;
    background-color: colors.$main-background;
}

//@Todo: Combine styling where possible

//Basic styles
.color-blue {
    color: colors.$buttonsNbars;
}

.xapit-utility {
    background-color: colors.$buttonsNbars;
    border: none;
    color: colors.$buttonsNbarsText
}

button.xapit-utility {
    width: 100px;
    padding: 5px;
    border-radius: 7px;
    font-weight: 600;
}

// Landing Page styles

// Forgot Password Page
.forgot-page-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}
.forgot-form-content {
    padding: 1.5em;
    border-radius: 16px;
    width: 480px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
    .forgot-form-header {
        border-bottom: 1px solid colors.$border-bottom;
        margin-bottom: 16px;
        h6 {
            text-align: center;
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 16px;
            width: 100%;
        }
    }
    button {
        border-radius: 16px;
        font-size: 1.25rem;
        font-weight: 600;
    }
}

.mb-3 {
    .forgot-form-email-header {
        font-size: 1.25rem;
        font-weight: 600;
        input {
            border-radius: 16px;
        }
    }
}

// Login Page
.login-page-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.center-login-title {
    margin-bottom: 25px;
}

.member-login-page {
    margin-bottom: 15px;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
}

.login-row-form {
    padding: 1.5em;
    border-radius: 16px;
    width: 480px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
    .login-page-input-container {
        display: block;
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 600;
        .form-control {
            border-radius: 16px;
        }
    }
}

.login-page-button-container {
    display: block;
    margin-top: 10px;
    .btn-primary {
        background-color: #00bcd4;
        border-color: transparent;
        border-radius: 16px;
        width: 100%;
    }
}

.login-page-sign-up {
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
    a {
        color: #2DBCD3;
        font-weight: 600;
    }
}

// End of login page

//Default Header Styling 
.default-header-container {
    position: fixed;
    width: 100%;
    background-color: colors.$buttonsNbars;
    top: 0;
    left: 0;
    z-index: 100;
}

.default-header-logo-container {
    display: flex;
    align-items: center;
    width: 15%;
    margin-left: 10px;
}

.small-header-logo-container {
    display: none;
}

.default-header-invite-friends {
    justify-content: center;
    text-align: center;
    color: colors.$content-background;
    font-size: 20px;
    padding: 5px;
}

.default-header-invite-friends {
    transition: 0.3s;
}

.default-header-invite-friends>a {
    text-decoration: none;
    color: colors.$buttonsNbarsText;
    font-size: inherit;
}

//Hover animation for invite friends 
.default-header-invite-friends:hover {
    padding: 10px;
    font-size: 28px;
    background-color: #b72041;
}

.default-header-utility {
    display: flex;
    justify-content: space-around;
    height: 75px;
    background-color: colors.$content-background;
}

.default-header-utility>img {
    width: 135px;
}

.default-header-utility-search {
    max-width: 30%;
    position: relative;
    padding: 0;
    margin-left: -10px;
}

.default-header-utility-search>form {
    height: 100%;
    display: flex;
    align-items: center;
}

.default-header-utility-search-input {
    border: 2px solid colors.$buttonsNbars;
    border-radius: 16px;
}

.default-header-utility-search-input-icon {
    position: absolute;
    right: 18px;
    z-index: 5;
    cursor: pointer;
}

.default-header-utility-search-preview {
    display: none;
    background-color: colors.$content-background;
    width: inherit;
    height: 20rem;
    position: absolute;
    top: 100%;
    z-index: 1;
    &.active {
        display: block;
    }
    overflow-y: auto;
    border: 2px solid colors.$highlight;
    border-radius: 16px;
}

.default-header-utility-search-preview-item {
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.5fr;
    row-gap: 16px;
    height: 4.5rem;
    max-height: 4.5rem;
    cursor: pointer;
    &:hover {
        background-color: colors.$highlight;
        .search-preview-item-type {
            color: colors.$default-text;
        }
    }
    .search-preview-item-image {
        max-height: inherit;
        display: flex;
        margin: 5px 0 0 10px;
        img {
            display: block;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
        }
    }
    .search-preview-item-content {
        max-height: inherit;
        height: inherit;
        margin-left: 15px;
    }
    .search-preview-item-title {
        color: colors.$default-text;
    }
    .search-preview-item-type {
        margin-top: 5px;
        color: colors.$subtext;
    }
}

.default-header-utility-actions {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    text-align: center;
}

.default-header-nav {
    display: flex;
    justify-content: center;
    background-color: colors.$buttonsNbars;
    height: 50px;
}

.default-header-nav>* {
    color: colors.$content-background;
    text-decoration: none;
    text-transform: uppercase;
    padding: 8px;
    margin: 0 45px 0 45px;
    font-weight: 600;
    font-size: 1.25rem;
}

.default-header-nav {
    .default-header-nav-dropdown {
        .dropdown-menu {
            .dropdown-item {
                &:hover:first-child {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
                &:hover:last-child {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
    }
}

.SideNav {
    display: none;
    position: relative;
    button {
        cursor: pointer;
        background: transparent;
        border: none;
        font-size: 32px;
        font-weight: 700;
        color: #2DBCD3;
    }
}

.SideNav .toggle-side-nav {
    position: fixed;
    // left: 10px;
    top: 50px;
    cursor: pointer;
    background: transparent;
    border: none;
    z-index: 9999;
}

.side-menu-nav {
    list-style: none;
    position: absolute;
    background: #2DBCD3;
    top: 60px;
    left: 0;
    width: 0;
    height: 100vh;
    overflow: hidden;
    max-width: 250px;
    z-index: 9;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
    transform: translateX(-100px);
    transition: transform ease-in-out 0.2s;
    a {
        color: #ffffff;
    }
}

.side-menu-nav.show-side-menu {
    width: 100%;
    transform: translateX(0px);
}

.side-menu-nav li a, .side-menu-nav li span {
    display: block;
    padding: 16px;
    border-bottom: 1px solid;
}

.responsive-profile-preview {
    color: #ffffff;
    .sidebar-profile-preview {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 10px;
        border-bottom: 1px solid;
        .profile-preview-header {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 5px;
        }
        .profile-preview-img-container a img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-bottom: 15px;
        }
    }
}

.default-header-nav-dropdown>* {
    padding: 0;
}

.default-header-nav-dropdown {
    color: colors.$buttonsNbarsText;
}

.default-header-nav-dropdown:hover {
    color: black;
}

.default-header-nav-dropdown>a,
.dropdown-toggle,
.dropdown-toggle>a {
    color: inherit;
    text-decoration: none;
}

.default-header-nav-dropdown>a>a {
    color: inherit;
}

// Override react-bootstrap nav dropdown ::after style (removes preset arrow)
a.dropdown-toggle.nav-link::after {
    display: none;
}

.default-header-nav-dropdown>a:hover {
    color: colors.$link-hover;
}

.default-header-nav>a:hover {
    color: colors.$link-hover;
}

.default-header-nav-dropdown-icon-animation {
    transition: 0.3s;
}

.default-header-nav-dropdown:hover>a>a>.default-header-nav-dropdown-icon-animation {
    transform: rotate(180deg);
}

// Ad column styling
.ad-column {
    max-width: 25%;
    padding: 0;
}

// News Feed
.news-feed-buttons-col {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
}

.news-feed-buttons {
    padding: 10px 0 10px 0;
}

//Posts styling
.create-post {
    background-color: colors.$content-background;
    margin-left: auto;
    margin-right: auto;
    padding: 8px 12px;
    border-radius: 16px;
    width: 100%;
    border: 1px solid #e2e5de;
}

.create-post-title {
    border-bottom: 2px solid colors.$main-background;
    h4 {
        font-weight: 600;
    }
}

.create-post-profile-pic-box-sizing {
    width: 10%;
}

.create-post-profile-pic {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    border: 1px solid #f2f0f0;
}

.create-post-form {
    padding: 1%;
    margin-top: 5px;
}

.create-post-box {
    width: 90%;
}

.create-post-form-text {
    resize: none;
    width: 100%;
    min-height: 56px;
    max-height: 250px;
    line-height: normal;
    font-size: 16px;
    box-shadow: none;
    border: none;
    overflow: auto;
    clear: both;
    outline: 0;
}

.create-post-form-text::-webkit-scrollbar {
    width: 8px;
}

.create-post-form-text::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.create-post-form-text::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.x-delete {
    font-weight: bolder;
    cursor: pointer;
    margin-right: 5px;
}

.react-datepicker__triangle {
    margin-left: 10px !important;
}

.create-post-actions-share-btn {
    width: 100px;
    background-color: colors.$buttonsNbars;
    color: colors.$buttonsNbarsText;
    border: none;
    border-radius: 16px;
    padding: 3px;
}

.create-post-actions {
    padding: 1%;
    margin-right: 2px;
    margin-bottom: 8px;
    height: 45px;
    justify-content: flex-end;
}

.create-post-actions>select {
    width: 150px;
    margin-right: 10px;
    border-radius: 16px;
    border: 1px solid #b2beb5;
}

.create-post-actions-datepicker-container {
    position: relative;
    width: 50px;
}

.create-post-actions-datepicker-btn {
    padding: 4px 8px;
    border-radius: 7px;
    border: none;
    background-color: transparent;
    border: 2px solid #607d8b;
    color: #607d8b;
}

.postFeedOverflow {
    overflow: inherit !important;
}

//react-datepicker popup class overwrite
.react-datepicker-popper {
    width: 350px;
}

// Create post options 
.create-post-actions-options {
    justify-content: flex-end;
    margin: 0 15px 0 0;
    padding: 5px 0 5px 0;
    width: 100%;
    border-top: 2px solid #e6ecf0;
    border-bottom: 2px solid #e6ecf0;
}

.create-post-actions-options>div {
    width: 35px;
    cursor: pointer;
    .fa-user-plus {
        color: #5492f5 !important;
    }
    .fa-face-smile {
        color: #d43ebd !important;
    }
    .fa-link {
        color: #ff9800 !important;
    }
    .fa-image {
        color: #4caf50 !important;
    }
    .fa-video {
        color: #ff5722 !important;
    }
}

.emoji-picker-container {
    position: relative;
    top: 25%;
    left: 50%;
}

.emoji-picker-wrapper {
    position: absolute;
    z-index: 9999;
}

.emoji-picker-toggle {
    cursor: pointer;
    width: 20px;
    float: right;
}

.posts-filter-btn {
    background-color: colors.$buttonsNbars;
    color: colors.$buttonsNbarsText;
    padding: 16px;
    border: none;
    border-radius: 16px;
    font-size: 1rem;
    letter-spacing: 0.5px;
}

.posts-filter-list {
    display: none;
    &.show-list {
        display: block !important;
    }
    position: absolute;
    background-color: colors.$content-background;
    border-radius: colors.$content-card-border-round;
    border: 1px solid black;
    z-index: 9999;
    margin-top: 8px;
    margin-left: 8px;
    .posts-filter-list-item {
        padding: 8px;
        cursor: pointer;
        color: colors.$default-text;
        &:hover {
            background-color: colors.$buttonsNbars;
            color: colors.$buttonsNbarsText;
            &:first-child {
                border-top-right-radius: 16px;
                border-top-left-radius: 16px;
            }
            &:last-child {
                border-bottom-right-radius: 16px;
                border-bottom-left-radius: 16px;
            }
        }
        &.active-filter {
            background-color: colors.$buttonsNbars;
            color: colors.$buttonsNbarsText;
            &:first-child {
                border-top-right-radius: 16px;
                border-top-left-radius: 16px;
            }
            &:last-child {
                border-bottom-right-radius: 16px;
                border-bottom-left-radius: 16px;
            }
        }
    }
}

.posts-settings-btn {
    background-color: colors.$buttonsNbars;
    color: colors.$buttonsNbarsText;
    border: none;
    border-radius: 16px;
    padding: 16px;
    margin-left: 10px;
}

.posts-more-btn {
    background-color: colors.$buttonsNbars;
    color: colors.$buttonsNbarsText;
    border: none;
    border-radius: 16px;
    padding: 16px;
    float: right;
}

.posts-feed {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}

.post-item {
    background-color: colors.$content-background;
    border: 1px solid #e4e0e0;
    border-radius: 16px;
    padding: 8px;
}

.post-item-container {
    // margin: 10px 0;
    border-radius: 16px;
    width: 100%;
    background-color: #fafafa;
}

.post-item-container-loading {
    display: flex;
    justify-content: center;
    padding: 8px;
}

.post-item-profile-pic {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #e4e4e4;
}

.post-item-display-name {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        &:hover {
            color: colors.$buttonsNbars;
        }
    }
}

.post-item-posted-date {
    color: colors.$subtext;
    font-size: 0.8rem;
}

.post-item-more-btn-container {
    margin-top: -10px;
    margin-left: auto;
    position: relative;
    span {
        font-size: 1.25rem;
        font-weight: 700;
        user-select: none; 
    }
    .post-item-settings-menu {
        display: none;
        &.show {
            display: flex;
            z-index: 99;
        }
        position: absolute;
        background-color: colors.$content-background;
        border: 1px solid black;
        border-radius: 16px;
        flex-direction: column;
        text-align: center;
        right: 50%;
        div {
            padding: 8px;
            width: 7rem;
            z-index: 999;
            &:hover {
                background-color: colors.$buttonsNbars;
            }
            cursor: pointer;
            span {
                font-size: 0.9rem;
                font-weight: 400;
                user-select: none;
            }
        }
    }
}

.post-item-settings-menu.show,
.header-list-widget-container,
.posts-filter-list.show-list,
.profile-action-dropdown-sub.active {
    animation: 0.3s fadein;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

@keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.post-item-more-btn-container .post-item-settings-menu div:nth-child(1) {
    border-bottom: 1px solid black;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.post-item-more-btn-container .post-item-settings-menu div:nth-child(2) {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

// .carousel-item {
//     div {
//         height: 480px;
//         .post-item-attachment-image {
//             background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);
//             object-fit: contain;
//             cursor: pointer;
//         }
//     }
// }

.post-item-attachment-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 480px;
    object-fit: contain;
    border-radius: 16px;
    background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);
    cursor: pointer;
}

.post-item-attachment-video video, iframe {
    display: block;
    width: 701px;
    max-width: 100%;
    height: 395px;
    border-radius: 16px;
}

.post-item-attachment-video>video, iframe {
    margin-left: auto;
    margin-right: auto;
}

.play-video-button {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: rgba(0,0,0,0.25);
    border-radius: 100%;
    top: 316px;
    left: 48%;
    margin: -30px 0 0 -30px;
    cursor: pointer;
}

.play-video-button:after {
    content: '';
    display: block;
    position: absolute;
    left: 22px;
    top: 10px;
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent transparent white;
}

  .play-video-button:hover {
    background-color: black;
}

.post-body-container {
    overflow-wrap: break-word;
}

.post-item-header {
    padding: 5px 0;
    white-space: pre-line;
}

.post-body-container {
    padding: 5px 8px;
    white-space: pre-line;
}

.post-body-container-content {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.post-item-sub-text {
    font-size: 0.8rem;
    opacity: 0.7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.post-item-sub-text span a,
.post-item-sub-text div a {
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
}

.post-item-footer {
    justify-content: space-around;
    border-top: 1px solid colors.$highlight;
    border-bottom: 1px solid colors.$highlight;
    margin-top: 15px;
    --bs-gutter-x: 0;
    div {
        font-weight: 500;
        width: 33%;
        cursor: pointer;
        text-align: center;
        padding: 8px 0;
    }
    div:hover {
        background-color: colors.$main-background;
        border-radius: 16px;
    }
}

.show-more-less-btn {
    background-color: transparent;
    padding: 4px 8px;
    border: none;
    color: #00a9be;
    font-weight: 700;
    margin-left: 10px;
}

.carousel-indicators {
    background-color: rgba(255,255,255,0.25);
    border-radius: 16px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0,0,0,0.5);
    border-radius: 50%;
}

// Suggestions list
.suggestion-list {
    list-style: none;
    padding: 0;
    height: 250px;
    overflow: hidden;
    overflow-y:scroll;
    font-weight: 600;
}

.suggestion-list-item {
    height: 70px;
    cursor: pointer;
    border-bottom: 1px solid colors.$border-bottom;
}

.suggestion-list-item:hover {
    background-color: colors.$highlight;
}

.suggestion-list-item>.row {
    height: inherit;
}

.suggestion-list-item-pic {
    height: inherit;
    display: flex;
    align-items: center;
}

.suggestion-list-item-pic>img {
    display: block;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid colors.$border-all;
}

.suggestion-list-item-label {
    display: flex;
    align-items: center;
}

.create-post-tagged-friends {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    padding-left: 1%;
}

.create-post-tagged-friends-item {
    background-color: colors.$highlight;
    display: inline;
    float: left;
    border-radius: 7px;
}

.create-post-tagged-friends-item>#tagged-friend-label {
    font-size: 0.75rem;
}

.create-post-tagged-friends-item>#tagged-friends-delete {
    font-weight: bolder;
    margin-right: 4px;
    cursor: pointer;
}

// Feelings / Activities 
.feelings-activities-menu {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}

.feelings-activities-menu>.row {
    cursor: pointer;
    width: 100%;
}

.feelings-activities-menu>.row>img {
    height: 50px;
    width: 50px;
    padding: 0;
    margin-left: 15px;
}

.feelings-activities-menu>.row>h5 {
    width: fit-content;
}

.create-post-selected-activity-feeling {
    margin-top: 10px;
}

.create-post-selected-activity-feeling>span {
    background-color: colors.$highlight;
    border-radius: 7px;
    display: inline;
    float: left;
    width: fit-content;
    padding: 4px;
    margin-left: 1%;
}

.create-post-selected-activity-feeling>span>#create-post-selected-activity-feeling-delete {
    font-weight: bolder;
    cursor: pointer;
    margin-right: 5px;
}

.create-post-added-link {
    margin-top: 10px;
}

.create-post-added-link>span {
    background-color: colors.$highlight;
    border-radius: 7px;
    display: inline;
    float: left;
    width: fit-content;
    padding: 4px;
    margin-left: 1%;
}

.upload-image-preview {
    margin-top: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.upload-image-preview>img {
    height: 150px;
    display: inline-block;
    margin-right: 5px;
}

.create-post-upload-image-preview {
    margin-top: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    max-width: 400px;
}

.create-post-upload-image-preview>div,
.create-post-upload-image-preview>div>img {
    height: 150px;
    max-width: 200px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
}

.image-delete-x {
    position: absolute;
    top: 0;
    left: 0.25rem;
    z-index: 2;
    font-weight: bolder;
    font-size: 1.5rem;
    line-height: 1rem;
    cursor: pointer;
    -webkit-text-stroke-width: 0.05rem;
    -webkit-text-stroke-color: white;
}

.image-delete-x:hover {
    color: colors.$highlight;
    -webkit-text-stroke-width: 0.05rem;
    -webkit-text-stroke-color: black;
}

.upload-video-preview {
    margin-top: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.upload-video-preview>video {
    height: 150px;
    display: inline-block;
    margin-right: 5px;
}

.create-post-video-preview {
    margin-top: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    max-width: 400px;
}

.create-post-video-preview>div {
    height: 150px;
    position: relative;
    display: inline-block;
    margin-right: 5px;
}

.create-post-video-preview>div>video {
    height: 150px;
}

.create-post-scheduled-date-preview {
    margin-top: 15px;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;
}

.create-post-scheduled-date-preview>span {
    background-color: colors.$highlight;
    border-radius: 7px;
    display: inline;
    float: left;
    width: fit-content;
    padding: 4px;
    margin-left: 1%;
}

// Comment styling
.author-item-name {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        &:hover {
            color: colors.$buttonsNbars;
        }
    }
}

.comment-container {
    background-color: #fafafa;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    display: flex;
    flex-direction: column-reverse;
}

.comment-item {
    display: grid;
    grid-template-columns: 0.1fr 1.5fr;
    border-bottom: 1px solid #e4e0e0;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 10px;
}

.comment-item:first-child {
    border-bottom: none;
}

.comment-item-profile-pic>a>img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-top: 4px;
}

.comment-item-actions {
    margin: 0;
    display: flex;
    flex-direction: row;
}

.comment-item-actions>p>span {
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
}

.comment-item-actions>p>span:first-child {
    margin-right: 10px;
}

.comment-item-actions>p>span:hover {
    text-decoration: underline;
    color: colors.$hover;
}

.comment-create {
    display: flex;
    flex-direction: row;
    padding: 16px;
    div:first-child {
        margin-left: -10px;
    }
}

.comment-create-text-box-container {
    position: relative;
    textarea {
        min-height: 60px;
        max-height: 120px;
        resize: none;
        width: 95%;
        font-size: 1rem;
        border: 1px solid colors.$border-all;
        border-radius: 16px;
        padding: 4px 8px;
        margin-left: 8px;
    }
    .comment-create-emoji-picker {
        margin-left: 8px;
    }
    .comment-create-text-box-send-btn {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        margin-right: 8px;
        &:hover {
            color: colors.$hover;
        }
    }
}

.comment-create>div>img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #e4e4e4;
}

.comment-create.nested {
    border-bottom: none;
    padding-left: 0;
}

.comment-create.nested > .comment-create-text-box-container > .comment-create-text-box-send-btn {
    margin-right: -15px;
}

.comment-item-actions-active {
    text-decoration: underline;
}

.comment-is-liked {
    color: colors.$buttonsNbars !important;
}

//Album photos 
.album-item {
    border: 1px solid colors.$highlight;
    border-radius: 16px;
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: 0.75fr 0.1fr 0.1fr;
    background-color: colors.$content-background;
    padding: 8px;
    margin: 0 10px 25px 10px;
}

.album-item:hover {
    border: 1px solid colors.$buttonsNbars;
}

.album-item>img {
    display: block;
    height: 200px;
    width: 100%;
    border-radius: 16px;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
}

.album-item>p {
    color: colors.$mute-text;
}

.album-photo-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    max-height: 700px;
    overflow-y: auto;
}

.album-photo-item {
    width: 200px;
    height: 200px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.album-photo-item>img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    cursor: pointer;
}

.album-photo-item-options {
    position: absolute;
    color: white;
    width: 100%;
    background-color: rgba($color: #0000009f, $alpha: 0.5);
    top: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 12px;
    height: 1.5rem;
    .album-photo-item-options-menu {
        position: absolute; 
        background-color: #2DBCD3;
        top: 1.5rem;
        display: none;
        border-radius: 7px;
        padding: 7px;
        flex-direction: column; 
        row-gap: 0.3rem;
        &.show {
            display: flex; 
        }
        .album-photo-item-options-menu-item {
            text-decoration: none;
            color: white;
            cursor: pointer; 
            &:hover {
                background-color: rgba($color: #0000009f, $alpha: 0.6);
            }
        }
    }
}

.album-photo-item-options>svg {
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}

.album-photo-item-info {
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    height: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: white;
    background-color: rgba($color: #0000009f, $alpha: 0.5);
}

.album-photo-item-info>div {
    margin-right: 0.75rem;
}

.album-photo-item-info>div>span {
    margin-right: 0.4rem;
}

//Sitepage Reviews

//Sitepage more dropdown 
.sitepage-more-dropdown {
    position: relative;
}

.sitepage-more-dropdown-menu {
    display: none;
    position: absolute;
    padding: 2px;
    top: 100%;
    background-color: #ffffff;
    margin-top: 1rem;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.3);
    ul {
        list-style-type: none;
        margin-bottom: 0;
        padding-left: 0;
        li {
            border: 1px solid #f2f0f0;
            padding: 7px;
        }
    }
}

.sitepage-more-dropdown-menu.show {
    display: block;
}

// Universal Group Styling
// GROUP SIDEBAR

.groups-sidebar-item-header {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    border-bottom: 1px solid colors.$highlight;
}

.groups-sidebar-recommended {
    .groups-recommended-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 1rem;
        margin-bottom: 1rem;
        .groups-recommended-image {
            img {
                width: 3rem;
                border-radius: 50%;
            }
        }
        .groups-recommended-info {
            margin-left: 0.3rem;
            p {
                padding: 0;
                margin: 0;
            }
            .groups-recommended-title {
                font-weight: 600;
                font-size: 0.9rem;
            }
            .groups-recommended-members {
                font-size: 0.8rem;
            }
            a {
                color: #000000;
                font-weight: 600;
            }
            a:hover {
                color: #00bcd4;
            }
        }
    }
}

.groups-members-grid {
    margin-top: 0.5rem;
}

.groups-members-grid-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 10px 0;
    width: 95% !important;
    border: 1px solid colors.$highlight;
    border-radius: 7px;
    .grid-item-image {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        width: 10%;
        margin-left: 10px;
        img {
            width: 75%;
            border-radius: 50%;
        }
    }
    .grid-item-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        line-height: 100%;
        margin-left: 10px;
        font-weight: 600;
        a {
            color: #000000;
        }
        a:hover {
            color: #00bcd4;
        }
    }
}

// END OF GROUP STYLING

// COMPONENT CONTENT VIEW / NAV
.component-content-view {
    padding: 1rem;
    background-color: colors.$content-background;
    border-radius: 16px;
}

.component-content-nav {
    display: inline-flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
    gap: 1rem;
    border-bottom: 1px solid colors.$highlight;
    width: 100%;
}

.component-content-nav-item {
    padding-bottom: 1rem;
    margin: 0;
    cursor: pointer;
    user-select: none;
}

.component-content-nav-item.active {
    color: colors.$buttonsNbars;
    border-bottom: 1px solid colors.$buttonsNbars;
}

.component-content-nav-item:hover {
    color: colors.$buttonsNbars;
}

// Component Album Photos
.album-photos-component {
    padding-top: 1rem;
}

.album-photos-component-create-album {
    cursor: pointer;
}

.album-photos-component-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
}

// Profiles Stlyes
// Override react-window <List /> styles
.posts-list {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.scroll-button {
    position: absolute;
    bottom: 1rem;
    left: 4rem;
    .btn-primary {
        background-color: #2DBCD3;
        border-color: #2DBCD3;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        font-size: 30px;
    }
}

.scroll-button-mini {
    display: none;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    .btn-primary {
        background-color: #2DBCD3;
        border-color: #2DBCD3;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        font-size: 30px;
    }
}

.attachment-modal {
    .modal-dialog {
        transform:translate(0, 300px) !important;
        min-height: calc(100% - 47.25rem) !important;
    }
}

.modal-content {
    border-radius: 16px;
    .modal-header {
        font-size: 1.5rem;
        font-weight: 600;
    }
    .modal-body {
        p {
            font-size: 1.25rem;
            font-weight: 600;
            margin-left: 0.5rem;
        }
        input {
            border-radius: 1rem;
            margin-bottom: 0.25rem;
        }
    }
}

// Media Queries
@media only screen and (max-width: 300px) {
    .Footer-Container {
        justify-content: normal;
        font-size: 9px;
        height: 20px;
    }
}

@media only screen and (max-width: 349px) {
    .create-post-actions {
        margin-right: 0;
    }
}

@media only screen and (max-width: 375px) {
    .Footer-Container {
        justify-content: normal;
        font-size: 11px;
        height: 20px;
    }
}

@media only screen and (max-width: 386px) {
    .create-post-actions>select {
        width: 120px;
    }
}

@media (min-width: 412px) {
    .sitepage-view-container {
        height: 1140px;
    }
}

@media only screen and (max-width: 430px) {
    .Footer-Container {
        justify-content: normal;
        font-size: 12px;
        height: 20px;
    }

    .scroll-button-mini {
        display: block;
    }

    .play-video-button {
        top: 225px;
    }

    .header-widget-action .header-list-widget-container {
        top: 14%;
    }

    .list-widget-friend-item .widget-friend-item-date {
        margin-left: 15px;
    }

    .create-post-actions>select {
        width: 125px;
    }

    .post-item-more-btn-container {
        margin-left: 15px;
    }

    .post-item-attachment-video {
        video {
            height: 200px;
        }
    }
    
    .album-photos-component-list {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .album-item-title,
    .album-item-numbers {
        display: flex;
        justify-content: center;
    }

    .sitepage-image-sm {
        display: flex;
    }

    .sitepage-image-container {
        bottom: 0;
    }

    .groups-browse-content-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 300px !important;
    }

    .groups-home-nav {
        display: flex;
        flex-direction: column;
        gap: 0;
        .groups-home-nav-header > h4 {
            font-size: 1.5rem;
        }
        .groups-home-nav-item > a {
            font-size: 1rem;
        }
    }

    .groups-browse-container {
        grid-template-columns: 1fr;
        column-gap: 0.5rem;
    }
}

@media only screen and (max-width: 540px) {
    .create-post-box {
        width: 85%;
        margin-left: 15px;
    }

    .comment-create-text-box-container textarea {
        width: 90% !important;
        margin-left: 7px;
    }

    .Footer-Container {
        position: fixed;
    }

    // Site Pages
    .sitepage-image-container {
        height: 120px !important;
        width: 120px !important;
        left: 0;
    }
}

@media only screen and (max-width: 742px) {
    .album-item > img {
        height: 160px;
        width: 160px;
    }
}

@media only screen and (max-width: 767px) {
    .default-header-invite-friends {
        display: none;
    }

    .default-header-utility {
        align-items: center;
        align-content: normal !important;
        height: 125px;
    }

    .small-header-logo-container {
        margin: -20px 0 0 -30px !important;
    }

    .default-header-utility-search {
        max-width: 60% !important;
        margin: -20px 0 0 -30px;
    }

    .default-header-utility-actions {
        width: 72% !important;
    }

    .SideNav .toggle-side-nav {
        top: 20px;
    }

    .side-menu-nav {
        top: 111px;
    }

    .emoji-picker-toggle {
        display: none;
    }

    // Site Pages

    .sitepages-search-input-container>select {
        height: inherit;
        width: 100%;
        margin: 10px 0;
    }

    .sitepage-view-container {
        max-width: 95%;
    }

    .sitepage-image-container {
        height: 160px;
        width: 160px;
        left: 0;
    }

    .featured-pages-nav {
        height: 48px;
    }

    .sitepage-header-box {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }

    .sitepage-header-buttons {
        justify-content: center;
        padding-right: 0;
    }

    .sitepage-header-text {
        padding-left: 0;
    }

    // Browse Groups and My Groups
    .groups-home-main-content {
        margin-left: auto;
        margin-right: auto;
    }

    .groups-sidebar-search,
    .groups-my-groups-sidebar-content {
        display: none;
    }

    .groups-view-wrapper {
        grid-template-columns: 1fr;
    }

    // .post-item-container {
    //     margin: 25px 0;
    // }

    .groups-members-view {
        height: 300px;
    }

    .groups-my-groups-container {
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 768px) {
    .vertical_side_nav {
        display: none;
    }

    .sitepage-view-container {
        height: 1360px;
    }

    .groups-view-container {
        height: 1225px;
    }
}

@media (min-width: 820px) {
    .sitepage-view-container {
        height: 1520px;
    }

    .groups-view-container {
        height: 1380px;
    }
}

@media only screen and (max-width: 835px) {
    div {
        .post-item-container {
            .comment-container {
                .comment-item {
                    .comment-item-body {
                        margin-left: 15px;
                        p {
                            width: 325px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 920px) {
    .default-layout-container {
        height: 1000px;
    }

    .default-header-nav {
        display: none;
    }

    .default-header-logo-container {
        display: none;
        margin-left: 0;
    }

    .small-header-logo-container {
        display: block;
        width: 12%;
        margin: 0;
    }

    .small-header-logo-container img {
        width: 48px;
        margin-left: 44px;
    }

    .SideNav {
        display: block;
        cursor: pointer;
        z-index: 9999;
    }
}

@media (min-width: 1024px) {
    .sitepage-view-container {
        height: 1650px
    }

    .groups-view-container {
        height: 1515px;
    }
}

@media only screen and (max-width: 1024px) {
    .default-header-utility {
        align-content: center;
    }
    
    .default-header-logo-container {
        width: 15%;
    }

    .default-header-utility-search {
        max-width: 40%;
    }

    .default-header-utility-actions {
        width: 30%;
    }

    .scroll-button {
        left: -8px !important;
    }

    .album-photo-list {
        padding: 15px 0;
    }
}

@media only screen and (max-width: 1199px) {
    .search-preview-item-title {
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .default-header-utility-actions {
        justify-content: space-between;
    }

    .comment-create-text-box-container textarea {
        width: 93%;
    }

    .scroll-button {
        left: 1rem;
    }
}

@media only screen and (min-width: 1200px) {
    .groups-view-container {
        width: 1200px;
    }

    .sitepage-view-container {
        width: 960px;
    }
}

@media only screen and (max-width: 1320px) {
    .default-header-nav>* {
        margin: 0 auto;
    }
}

//Test
.layout-left {
    height: 100%;
}

//Timeline wrapper 
.timeline-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // flex-grow: 1;
}

//Timeline slider 
.timeline-slider {
    height: 60%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: colors.$content-background;
    font-weight: 600;
}

.timeline-track {
    background-color: colors.$timeline-slider;
    width: 0.75rem;
    border: 1px solid colors.$subtext;
    border-radius: 1rem;
}

.timeline-thumb {
    width: 90px;
    height: 40px;
    border-radius: 1rem;
    background-color: #00bcd4;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
}