//Use Light color scheme 
@use "../../Light.scss" as colors;


.event-edit-container {
    display: flex;
    height: 100%;
    width: 100%;
    color: colors.$default-text;
    margin-top: 16px;
    .event-edit-form-container {
        background-color: colors.$content-background;
        padding: 16px;
        margin: 0 auto 0 auto;
        .event-edit-form-header {
            font-size: 1.1rem;
            // font-weight: bold; 
            padding-bottom: 8px;
            margin-bottom: 8px;
            border-bottom: 1px solid colors.$highlight;
        }
    }
}