// @use light color scheme 

@use "../../Light.scss" as colors;

// Styles for Advertisement Component
.advertise-content-box {
    background-color: colors.$content-background;
    border-radius: colors.$content-card-border-round;
    padding: 16px;
    margin-top: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .advertise-content-box-header {
        font-size: 1.5rem;
        font-weight: 700;
        width: 100%;
        border-bottom: 1px solid colors.$default-text;
    }
    .box-content {
        height: 100%;
        width: 100%;
        .advertise-create-form {
            form {
                div.row {
                    margin-top: 8px;
                    div.col {
                        div.mb-3 {
                            .advertise-create-form-header {
                                margin-bottom: 8px;
                                span {
                                    font-weight: 500;
                                }
                            }
                            label.form-label {
                                font-weight: 500;
                            }
                            select.form-select, input {
                                border-radius: 16px;
                            }
                        }
                    }
                }
                div {
                    button {
                        border-radius: 16px;
                    }
                }
            }
        }
    }
}

.ad-board-item {
    display: grid;
    grid-template-rows: 0.1fr 0.7fr 0.2fr;
    background-color: colors.$highlight;
    padding: 8px;
    row-gap: 1.6px;
    border-radius: 7px;
    position: relative; 
    img {
        height: 125px;
        max-width: 100%;
        margin: 2px auto auto;
    }
    .ad-board-item-title {
        display: flex; 

        width: 100%;    
        a {
            text-decoration: none;
            color: inherit;
            &:hover {
                color: colors.$alt-link-hover;
            }
        }
        .ad-board-item-options-menu-wrapper {
            margin-left: auto;
            position: relative; 
            svg { 
                cursor: pointer;
                &:hover {
                    color: colors.$alt-link-hover;
                }
            }
            .ad-board-item-options-menu {
                display: none;
                &.show {
                    display: flex;
                }
                flex-direction: column;
                width: max-content;
                position: absolute;
                z-index: 901;
                right: 16px;
                background-color: colors.$content-background;
                border: black 1px solid;
                border-radius: 7px;
                padding-top: 8px;
                padding-bottom: 8px;
                top: 16px;

                .ad-board-menu-action {
                    cursor: pointer; 
                    color: colors.$default-text;
                    font-size: 0.9rem;
                    width: 100%;
                    padding-left: 8px;
                    padding-right: 8px;
                    &:hover {
                        background-color: colors.$highlight;
                    }
                }
            }
        }
    }
    .ad-board-item-text {
        display: flex;
        justify-content: center;
    }
}

.campaign-row {

    .campaign-row-options {
        display: flex;
        justify-content: space-evenly;
        span {
            cursor: pointer;
            &:hover {
                color: colors.$alt-link-hover;
            }
        }
    }
}

.advertise-create-choose-package {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    height: 100%;
}

.advertise-create-choose-package .advertisement-package-content {
    display: flex;
    flex-direction: column;
    background-color: colors.$highlight;
    border-radius: colors.$content-card-border-round;
    padding: 16px;
    margin-top: 10%;
    justify-content: center;
    text-align: center;
}

.advertise-create-choose-package .advertisement-package-content .advertisement-package-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 32px;
}

.advertise-create-choose-package .advertisement-package-content .advertisement-package-description {
    font-size: 1.1rem;
}

.advertise-create-form-image-preview {
    position: relative;
    width: 100%;
    max-height: 100%;
    height: 0;
    padding-bottom: 100%;
    border: 1px solid colors.$default-text;
    border-radius: 7px;
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        padding: 7px;
    }
}

.advertisement-stats {
    

    .advertisement-stats-filters {
        display: flex; 
        justify-content: space-evenly; 
        div {
            width: 30%;
        }
    }
}

// Media queries
@media (max-width: 600px) {
    .advertise-content-box .box-content .advertise-create-form form div.row div.col {
        flex: none;
    }

    .advertise-content-box .box-content .advertise-create-form form div.row div.col div.advertise-create-form-image-preview {
        margin-bottom: 16px;
    }
}