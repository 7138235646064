//@use light theme colors 
@use "../../Light.scss" as colors;

// Styles file for Create Page component
.pages-create-container {
    background-color: colors.$content-background;
    border-radius: colors.$content-card-border-round;
    padding: 1rem;
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    form {
        p {
            font-size: 1.3rem !important;
            font-weight: 700;
        }
        div.col {
            div.row {
                div.col {
                    label {
                        font-weight: 600;
                    }
                    input {
                        border-radius: 15px;
                    }
                }
                select.form-control {
                    border-radius: 15px;
                    appearance: auto;
                }
            }
            label {
                font-weight: 600;
            }
            textarea.form-control {
                border-radius: 15px;
                resize: none;
            }
        }
        div {
            label {
                font-weight: 600;
            }
            select {
                border-radius: 15px;
                appearance: auto;
            }
        }
        button.Pages-create-form-submit {
            border-radius: 15px;
        }
    }
}

.Pages-create-form-submit {
    margin-top: 1rem;
}