//@use light color theme
@use "../../Light.scss" as colors; 

.videos-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    .videos-nav {
        background-color: colors.$content-background;
        border-radius: colors.$content-card-border-round;
        padding: 1rem;
        margin-top: 180px;
        width: 100%;
        .videos-nav-header {
            display: inline;
            margin-right: 1rem;
            font-size: 1.4rem;
            font-weight: 500;
        }
        .videos-nav-item {
            height: 100%;
            display: inline;
            margin-right: 1rem;
            color: black;
            font-size: 1rem;
            font-weight: 500;
            &.active {
                color: colors.$alt-link-hover;
                border-bottom: 1px solid colors.$alt-link-hover;
            }
        }
    }
}