//@use light color scheme 
@use "../../Light.scss" as colors;

.events-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    .events-nav {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: colors.$content-background;
        border-radius: colors.$content-card-border-round;
        padding: 1rem;
        width: 100%;
        .events-nav-header {
            display: inline;
            margin-right: 1rem;
            font-size: 2rem;
            font-weight: 700;
        }
        .events-nav-item {
            height: 100%;
            display: inline;
            margin-right: 1rem;
            color: black;
            font-size: 1.25rem;
            font-weight: 600;
            &.active {
                color: colors.$alt-link-hover;
            }
        }
    }
}

.events-container .events-content-container .events-my-container div div .events-list,
.events-container .events-content-container .events-past-container div div .events-list {
    height: auto !important;
}

//Universal list display for events dashboard route 
.events-content {
    width: 100%;
    height: 100%;
    min-height: 85vh;
    max-height: 85vh;
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    column-gap: 2rem;
    margin-top: 1rem;
    .events-content-sidebar {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        height: 100%;
        width: 100%;
        .events-content-sidebar-item {
            display: flex;
            flex-direction: column;
            // row-gap: 1rem;
            background-color: colors.$content-background;
            border-radius: colors.$content-card-border-round;
            padding: 1rem;
            width: 100%;
            .events-content-sidebar-item-header {
                display: flex;
                flex-direction: column;
                row-gap: 0.5rem;
                font-size: 1.2rem;
                font-weight: 500;
            }
            .events-content-sidebar-item-content {
                display: flex;
                flex-direction: column;
                row-gap: 0.5rem;
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
}

//Universal list item for virtualized events list 
.default-event-list-item {
    display: grid;
    grid-template-columns: 0.3fr 0.4fr 0.3fr;
    align-items: center;
    background-color: colors.$content-background;
    border-radius: colors.$content-card-border-round;
    padding: 0.5rem;
    column-gap: 1rem;
    cursor: pointer;
    img {
        max-width: 100%;
        max-height: 100%;
        height: 255px;
        overflow: hidden;
    }
    .default-event-list-item-info {
        display: flex;
        flex-direction: column;
        row-gap: 0.3rem;
        justify-content: center;
        svg {
            height: 1.1rem;
            width: 1.1rem;
            margin-right: 5px;
        }
        .default-event-list-item-title {
            font-size: 1.1rem;
            font-weight: 600;
            cursor: pointer;
            span {
                &:hover {
                    color: colors.$alt-link-hover;
                }
            }
        }
        .default-event-list-item-guest-count {
            span {
                font-weight: 600;
                cursor: pointer;
                &:hover {
                    color: colors.$alt-link-hover;
                }
            }
        }
        .default-event-list-item-start-date {
        }
        .default-event-list-item-location{
        }
    }
    .default-event-list-item-actions {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        justify-content: center;
        align-items: flex-end;
        margin-left: auto;
        width: 100%;
        button {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 5rem;
            font-size: 0.8rem;
            svg {
                margin-right: 5px;
            }
        }
    }
}

// Media Queries
@media only screen and (max-width: 540px) {
    .events-content {
        min-height: 70vh !important;
        grid-template-columns: 1fr;
    }

    .default-event-list-item {
        grid-template-columns: 0.4fr 0.6fr 0.2fr;
        align-items: center;
        height: 200px !important;
        img {
            height: 115px !important;
            width: 115px !important;
            max-width: none;
        }
    }

    .default-event-list-item .default-event-list-item-info .default-event-list-item-title {
        font-size: 1.1rem;
    }

    .default-event-list-item-guest-count,
    .default-event-list-item-location,
    .default-event-list-item-start-date {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 767px) {
    .events-container {
        display: block;
        margin-left: auto;
        width: 99%;
    }

    .events-content {
        min-height: 100vh;
        grid-template-columns: 1fr;
    }

    .events-content-sidebar {
        display: none !important;
    }
}

@media (max-height: 920px) {
    .events-content {
        min-height: 100vh;
        max-height: 100vh;
    }
}

@media (max-width: 920px) {
    .events-container .events-nav {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
}

@media (max-width: 1024px) {
    .default-event-list-item img {
        height: 150px;
        width: 150px;
    }
}

@media only screen and (min-width: 1200px) {
    .events-container {
        width: 1200px;
    }
}